import React, { useRef, useState, useEffect } from "react";

const ImageCanvas = ({ imageSrc, onSelectArea }) => {
	const canvasRef = useRef(null);
	const [drawing, setDrawing] = useState(false);
	const [start, setStart] = useState({ x: 0, y: 0 });
	const [shapes, setShapes] = useState([]);
	const [image, setImage] = useState(null);

	useEffect(() => {
		if (imageSrc) {
			const img = new Image();
			img.src = imageSrc;
			img.onload = () => {
				setImage(img);
				const canvas = canvasRef.current;
				canvas.width = img.width;
				canvas.height = img.height;
				const ctx = canvas.getContext("2d");
				ctx.drawImage(img, 0, 0, img.width, img.height);
			};
		}
	}, [imageSrc]);

	useEffect(() => {
		if (image && canvasRef.current) {
			const ctx = canvasRef.current.getContext("2d");
			ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
			ctx.drawImage(image, 0, 0, image.width, image.height);
			shapes.forEach((shape) => {
				drawShape(ctx, shape);
			});
		}
	}, [image, shapes]);

	const drawShape = (ctx, shape) => {
		ctx.strokeStyle = "#017AFF";
		ctx.lineWidth = 2;
		ctx.setLineDash([5, 5]);
		ctx.strokeRect(
			shape.start.x,
			shape.start.y,
			shape.end.x - shape.start.x,
			shape.end.y - shape.start.y
		);
		ctx.fillStyle = "rgb(89 179 244 / 30%)";
		ctx.fillRect(
			shape.start.x,
			shape.start.y,
			shape.end.x - shape.start.x,
			shape.end.y - shape.start.y
		);
	};

	const handleMouseDown = (e) => {
		if (!image) return;

		setDrawing(true);
		const rect = canvasRef.current.getBoundingClientRect();
		const x = e.clientX - rect.left;
		const y = e.clientY - rect.top;
		setStart({ x, y });
	};

	const handleMouseUp = (e) => {
		if (!image) return;

		setDrawing(false);
		const rect = canvasRef.current.getBoundingClientRect();
		const x = e.clientX - rect.left;
		const y = e.clientY - rect.top;

		if (x !== start.x && y !== start.y) {
			const newShape = {
				start: {
					x: Math.min(start.x, x),
					y: Math.min(start.y, y),
				},
				end: {
					x: Math.max(start.x, x),
					y: Math.max(start.y, y),
				},
			};

			setShapes([...shapes, newShape]);
			onSelectArea(newShape);
		}
	};

	const handleMouseMove = (e) => {
		if (!image || !drawing) return;

		const rect = canvasRef.current.getBoundingClientRect();
		const currentX = e.clientX - rect.left;
		const currentY = e.clientY - rect.top;

		const ctx = canvasRef.current.getContext("2d");
		ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
		ctx.drawImage(image, 0, 0, image.width, image.height);
		shapes.forEach((shape) => {
			drawShape(ctx, shape);
		});

		ctx.strokeStyle = "#017AFF";
		ctx.lineWidth = 2;
		ctx.setLineDash([5, 5]);
		ctx.strokeRect(start.x, start.y, currentX - start.x, currentY - start.y);
		ctx.fillStyle = "rgb(89 179 244 / 30%)";
		ctx.fillRect(start.x, start.y, currentX - start.x, currentY - start.y);
	};

	return (
		<canvas
			ref={canvasRef}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseMove={handleMouseMove}
			style={{
				border: "1px solid black",
				maxWidth: "100%",
				height: "auto",
				cursor: "crosshair",
			}}
		/>
	);
};

export default ImageCanvas;
