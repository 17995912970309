import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Button } from "../../shadcn-components/ui/button";
import DataTableFacetedFilter from "../common/DataTableFacetedFilter";
import { useFetchFilters } from "../../hooks/useFetchFilters";
import baseUrl from "../../config/baseUrl";

const DataToolbar = ({ table, apiUrl, clientID }) => {
	const { id, is_admin } = useContext(AuthContext);

	const [category, setCategory] = useState([]);
	const [subCategory, setSubCategory] = useState([]);

	const clientQuery = clientID ? `&contact_id=${clientID}` : "";

	const [data, setParams, loading, error] = useFetchFilters(
		`${baseUrl}${apiUrl}?isAdmin=${is_admin}&userId=${id}${clientQuery}`
	);

	const isFiltered = table.getState().columnFilters.length > 0;

	const filterColumns = table
		.getAllColumns()
		.filter((column) => column.columnDef?.filterable)
		.map((column) => ({
			id: column.id,
			displayName: column.columnDef.displayName,
		}));

	const handleResetFilter = () => {
		table.resetColumnFilters();
		setParams({});
	};

	/*
	This function is responsible for fetching the category and subcategory for documents section table 
	*/
	const fetchCategory = async () => {
		try {
			const res = await fetch(
				`${baseUrl}/api/get-document-filter-name?${is_admin}=1&userId=${id}`
			);

			const data = await res.json();

			console.log(data);

			setCategory(data.category);
			setSubCategory(data.subcategory);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		fetchCategory();
	}, []);

	return (
		<div className="my-2 flex gap-2 items-center flex-wrap">
			<p className="text-sm text-muted-foreground uppercase">Filter by</p>
			{filterColumns.map((column) => {
				let columnOptions = data[column.id] || [];
				if (column.id === "doc_category") columnOptions = category;
				if (column.id === "document_subtype") columnOptions = subCategory;
				return (
					<DataTableFacetedFilter
						key={column.id}
						column={table.getColumn(column.id)}
						title={column.displayName}
						table={table}
						options={columnOptions}
						setParams={setParams}
						loading={loading}
					/>
				);
			})}

			{isFiltered && (
				<Button
					variant="ghost"
					onClick={handleResetFilter}
					className="h-8 px-2 lg:px-3"
				>
					Reset
					<Cross2Icon className="ml-2 h-4 w-4" />
				</Button>
			)}
		</div>
	);
};

export default DataToolbar;
