import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MdModeEdit } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import EditDocumentForm from "./EditDocumentForm";
import ButtonUI from "../material-ui/ButtonUI";
import editDocument from "../../api/editDocument";
import { AuthContext } from "../../context/Auth/AuthContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const BootstrapDialogTitle = (props) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<RiCloseCircleFill />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

const editDocumentFormSchema = yup.object().shape({
	document_name: yup.string().required("Document name is required field"),
	// document_desc: yup
	//   .string()
	//   .required("Document Description is required field"),
	//category: yup.string().nullable().required("Category is required field"),
	subcategory: yup.string().required("Sub Category is required field"),
	//document_status: yup.string().required("Status is required field"),
});

const EditDocumentPopup = ({
	setDocumentUpdatedToTrue,
	tableValues,
	//categoryOptions,
	subCategoryOptions,
	clientNames,
}) => {
	const [open, setOpen] = useState(false);
	const { token, user_id } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(editDocumentFormSchema),
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onEditDocumentFormSubmit = async (editDocumentFormData) => {
		setLoading(true);
		const editDocumentFormResponse = await editDocument({
			token: token,
			user_id: user_id,
			document_id: tableValues.document_id,
			document_name: editDocumentFormData.document_name,
			document_desc: editDocumentFormData.document_desc,
			//category: tableValues.category_id,
			document_sub_category: editDocumentFormData.subcategory,
			//status: editDocumentFormData.document_status,
		});

		if (editDocumentFormResponse.code === 200) {
			setLoading(false);
			setDocumentUpdatedToTrue();
			setOpen(false);

			toast.success("Document is updated successfully", {
				position: "top-center",
				autoClose: 3000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		} else {
			setLoading(false);
			toast.error("Error while updating document", {
				position: "top-center",
				autoClose: 5000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	return (
		<div className="dialog-modal">
			<ButtonUI
				formButton={false}
				variant="outlined"
				buttonText={<MdModeEdit />}
				style={{
					backgroundColor: "#17a2b8",
					color: " white",
					marginRight: "5px",
					padding: "8px",
					minWidth: "fit-content",
					border: "none",
				}}
				onClickFunc={handleClickOpen}
			/>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				className="custom-edit-modal"
				open={open}
			>
				<BootstrapDialogTitle
					id="customized-dialog-title"
					className="edit-doc-modal"
					onClose={handleClose}
				>
					<div className="popup-header-svg-icon">
						<MdModeEdit />
					</div>
					<div className="popup-header-title">Edit Document</div>
				</BootstrapDialogTitle>
				<DialogContent dividers>
					<EditDocumentForm
						register={register}
						control={control}
						errors={errors}
						tableValues={tableValues}
						//categoryOptions={categoryOptions}
						subCategoryOptions={subCategoryOptions}
					/>
				</DialogContent>
				<DialogActions sx={{ paddingBottom: "15px !important" }}>
					<div className="popup_submit_btn">
						<ButtonUI
							loading={loading}
							variant="contained"
							buttonText="Submit"
							style={{
								width: "150px",
								height: "35px",
								backgroundColor: "#007bff",
								boxShadow: "none",
								border: "1px solid #007bff",
								fontWeight: "500",
								borderRadius: "4px",
								letterSpacing: "0.07rem",
								marginTop: "20px",
							}}
							handleSubmit={handleSubmit}
							onFormSubmit={onEditDocumentFormSubmit}
						/>
					</div>
				</DialogActions>
			</BootstrapDialog>
		</div>
	);
};

export default EditDocumentPopup;
