import React, { useState, useEffect, useContext } from "react";
import documentData from "../../../../../api/documents";
import { AuthContext } from "../../../../../context/Auth/AuthContext";
import {
	ChevronDownIcon,
	CaretSortIcon,
	MixerHorizontalIcon,
} from "@radix-ui/react-icons";
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";

import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "../../../../../shadcn-components/ui/tooltip";

import { Button } from "../../../../../shadcn-components/ui/button";
import { Checkbox } from "../../../../../shadcn-components/ui/checkbox";
import PaginationSection from "../../../../common/PaginationSection";
import TableSkeleton from "../../../../common/TableSkeleton";
import DataToolbar from "../../../../common/DataToolbar";
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
	DropdownMenuItem,
} from "../../../../../shadcn-components/ui/dropdown-menu";
import { Input } from "../../../../../shadcn-components/ui/input";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../../../../../shadcn-components/ui/table";

import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../../../../../shadcn-components/ui/select";

import DeleteDocumentPopup from "../../../../document-table/DeleteDocumentPopup";
import CopyLinkPopup from "../CopyLinkPopup";
import ShareDocumentPopup from "../ShareDocumentPopup";
import DocumentViewer from "../DocumentViewer";
import baseUrl from "../../../../../config/baseUrl";
import "../../../../../styles/DocumentTable.css";
import { fileIcon } from "../../../../common/FileIcons";
import viewDocumentLink from "../../../../../api/viewDocumentLink";
import BulkDeletePopup from "../../../../document-table/BulkDeletePopup";
import { toast } from "react-toastify";
// import { Tooltip } from "@mui/material";
import { BsFillCloudArrowUpFill } from "react-icons/bs";
import { TbCircleCheck } from "react-icons/tb";
import { TiDeleteOutline } from "react-icons/ti";
import { useDebounce } from "use-debounce";

const DocumentTable = ({
	clientID,
	selectedDocCategoryType,
	changeDocumentViewerState,
	documentUpdatedToTrue,
	setDocumentUpdatedToTrue,
}) => {
	const [loading, setLoading] = useState(false);
	const [pageCount, setPageCount] = useState(0);
	const [documentDeleted, setDocumentDeleted] = useState(0);
	const [bulkDeleteDocument, setBulkDeleteDocument] = useState(false);
	const [bulkDeleteDocumentIds, setBulkDeleteDocumentIds] = useState();

	const [documentResponse, setDocumentResponse] = useState([]);

	const [sorting, setSorting] = useState([]);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnVisibility, setColumnVisibility] = useState({});
	const [rowSelection, setRowSelection] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalPages, setTotalPages] = useState(1);
	const [globalFilter, setGlobalFilter] = useState("");
	const [debouncedGlobalFilter] = useDebounce(globalFilter, 1000);

	const [selectAllChecked, setSelectAllChecked] = useState();
	const [showFilterButton, setShowFilterButton] = useState(false);

	let docCategory = `&category_id=${selectedDocCategoryType.toLowerCase()}`;
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 20,
	});

	const setDocumentDeletedToTrue = () => {
		setDocumentDeleted((count) => count + 1);
	};

	const { token, user_id, is_admin } = useContext(AuthContext);

	const documentResponseFunc = async () => {
		setLoading(true);
		const offset = currentPage - 1;
		const filterParams = {};

		columnFilters.forEach((filterItem) => {
			filterParams[filterItem.id] = filterItem.value;
		});
		const sortParam = sorting
			.map((sortItem) => {
				return `${sortItem.id}=${sortItem.desc ? "desc" : "asc"}`;
			})
			.join(",");
		const params = {
			contact_id: clientID,
			isAdmin: is_admin,
			userId: user_id,
			limit: pageSize,
			offset: offset,
			...filterParams,
			global_search: debouncedGlobalFilter,
			sort: sortParam,
		};
		const queryString = new URLSearchParams(params).toString();
		const url = `${baseUrl}/api/get-all-doc-data?${queryString}${docCategory}`;

		const response = await documentData({ token, api: url });

		if (response?.code === 200) {
			const rowData = response.data[0].document.map((row) => ({
				...row,
				id: row.document_id,
			}));

			setDocumentResponse(rowData);
			setTotalPages(Math.ceil(response?.data[0]?.alldocumentcount / pageSize));
		} else {
			setTotalPages(1);
			setDocumentResponse([]);
		}
		setLoading(false);
	};

	const openLinkInNewTab = async (rowData) => {
		const downloadLinkFormResponse = await viewDocumentLink({
			token: token,
			docId: rowData.document_id,
		});

		if (
			downloadLinkFormResponse?.code === 200 &&
			downloadLinkFormResponse?.data[0]?.pdflink
		) {
			const link = downloadLinkFormResponse?.data[0].pdflink;
			//window.open(`${link}`);
			changeDocumentViewerState(
				rowData.document_id,
				rowData.document_request_id,
				downloadLinkFormResponse?.data[0].document_status,
				`${link}`,
				rowData.document_name,
				rowData.category_id,
				rowData.document_desc
			);
		} else {
			toast.error("Download URL does not Exists", {
				position: "top-center",
				autoClose: 5000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	const handleBulkAction = async (actionType) => {
		const selectedRowsData = table
			.getFilteredSelectedRowModel()
			.rows.map((row) => row.original);
		const ids = selectedRowsData.map((row) => row.document_id).join(",");
		if (actionType === "delete") {
			setBulkDeleteDocument(true);
			setBulkDeleteDocumentIds(ids);
		}
	};

	useEffect(() => {
		documentResponseFunc();
		setSelectAllChecked(false);
	}, [
		selectedDocCategoryType,
		documentDeleted,
		bulkDeleteDocument,
		documentUpdatedToTrue,
		currentPage,
		columnFilters,
		debouncedGlobalFilter,
		sorting,
		pageSize,
	]);

	const columns = [
		{
			id: "select",
			header: ({ table }) => (
				<div className="flex items-center">
					<Checkbox
						checked={
							table.getIsAllPageRowsSelected() ||
							(table.getIsSomePageRowsSelected() && "indeterminate")
						}
						onCheckedChange={(value) =>
							table.toggleAllPageRowsSelected(!!value)
						}
						aria-label="Select all"
					/>
					{(table.getIsSomePageRowsSelected() ||
						table.getIsAllPageRowsSelected()) && (
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button
									variant="outline"
									className="p-0 !border-none !shadow-none bg-transparent"
								>
									<ChevronDownIcon className="" />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="end">
								<DropdownMenuItem onSelect={() => handleBulkAction("delete")}>
									Delete
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					)}
				</div>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => {
						setSelectAllChecked(true);
						row.toggleSelected(!!value);
					}}
					aria-label="Select row"
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: "document_type",
			filterable: true,
			displayName: "Type",
			header: ({ column }) => {
				return (
					<Button
						variant="ghost"
						onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
						className="p-0"
					>
						Type
						<CaretSortIcon className="ml-2 h-4 w-4" />
					</Button>
				);
			},
			cell: ({ row }) => (
				<div className="w-full flex items-center">
					{fileIcon[row.getValue("document_type")]}
				</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "document_name",
			displayName: "Document Name",
			filterable: false,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Document Name
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => {
				const document_name = row.getValue("document_name");

				return <div className="capitalize text-left ">{document_name}</div>;
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "document_request_setting_name",
			displayName: "Linked Request",
			filterable: true,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Linked Request
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => {
				const document_request_name = row.getValue(
					"document_request_setting_name"
				);

				return (
					<div className="flex text-left items-center justify-start">
						<span className="flex items-center justify-center case-doc-tables mr-4">
							{row.original.document_request_status ? (
								row.original.document_request_status?.toLowerCase() ===
								"requested" ? (
									<TooltipProvider>
										<Tooltip>
											<TooltipTrigger>
												<span className="request-doc-request-icon request-doc-icon">
													<BsFillCloudArrowUpFill />
												</span>
											</TooltipTrigger>
											<TooltipContent className="bg-gray-600">
												<p>Requested</p>
											</TooltipContent>
										</Tooltip>
									</TooltipProvider>
								) : row.original.document_request_status.toLowerCase() ===
								  "uploaded" ? (
									<TooltipProvider>
										<Tooltip>
											<TooltipTrigger>
												<span className="request-doc-upload-icon request-doc-icon">
													<BsFillCloudArrowUpFill />
												</span>
											</TooltipTrigger>
											<TooltipContent className="bg-gray-600">
												<p>Uploaded</p>
											</TooltipContent>
										</Tooltip>
									</TooltipProvider>
								) : row.original.document_request_status.toLowerCase() ===
								  "approved" ? (
									<TooltipProvider>
										<Tooltip>
											<TooltipTrigger>
												<span className="request-doc-approved-icon request-doc-icon">
													<TbCircleCheck />
												</span>
											</TooltipTrigger>
											<TooltipContent className="bg-gray-600">
												<p>Approved</p>
											</TooltipContent>
										</Tooltip>
									</TooltipProvider>
								) : row.original.document_request_status.toLowerCase() ===
								  "rejected" ? (
									<TooltipProvider>
										<Tooltip>
											<TooltipTrigger>
												<span className="request-doc-rejected-icon request-doc-icon">
													<TiDeleteOutline />
												</span>
											</TooltipTrigger>
											<TooltipContent className="bg-gray-600">
												<p>Rejected</p>
											</TooltipContent>
										</Tooltip>
									</TooltipProvider>
								) : null
							) : null}
						</span>
						{document_request_name}
					</div>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		// {
		//   accessorKey: "document_status",
		//   displayName: "Status",
		//   header: ({ column }) => (
		//     <Button
		//       variant="ghost"
		//       onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
		//       className="p-0"
		//     >
		//       Status
		//       <CaretSortIcon className="ml-2 h-4 w-4" />
		//     </Button>
		//   ),
		//   cell: ({ row }) => (
		//     <div className="text-left">
		//       {row.getValue("document_status").toLowerCase() === "active"
		//         ? "Active"
		//         : "Inactive"}
		//     </div>
		//   ),
		//   filterFn: (row, id, value) => {
		//     return value.includes(row.getValue(id));
		//   },
		// },
		{
			accessorKey: "doc_category",
			displayName: "Category",
			filterable: true,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Category
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("doc_category")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "document_subtype",
			displayName: "Sub Category",
			filterable: true,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Sub Category
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("document_subtype")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "created_at",
			displayName: "Created At",
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Created At
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("created_at")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "updated_at",
			displayName: "Modified At",
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Modified At
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("updated_at")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			id: "actions",
			displayName: "Actions",
			header: ({ column }) => (
				<Button variant="ghost" className="p-0 !cursor-default">
					Actions
				</Button>
			),
			enableHiding: false,
			cell: ({ row }) => {
				return (
					<div className="flex gap-2">
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger>
									<span>
										<CopyLinkPopup tableValues={row.original} />
									</span>
								</TooltipTrigger>
								<TooltipContent className="bg-gray-600">
									<p>Copy</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>

						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger>
									<span>
										<ShareDocumentPopup tableValues={row.original} />
									</span>
								</TooltipTrigger>
								<TooltipContent className="bg-gray-600">
									<p>Share</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>

						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger>
									<span>
										<DocumentViewer
											tableValues={row.original}
											changeDocumentViewerState={changeDocumentViewerState}
										/>{" "}
									</span>
								</TooltipTrigger>
								<TooltipContent className="bg-gray-600">
									<p>View</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>

						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger>
									<span>
										<DeleteDocumentPopup
											setDocumentDeletedToTrue={setDocumentDeletedToTrue}
											data={row.original}
										/>
									</span>
								</TooltipTrigger>
								<TooltipContent className="bg-gray-600">
									<p>Delete</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					</div>
				);
			},
		},
	];

	const table = useReactTable({
		data: documentResponse,
		columns,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		initialState: {
			pageSize: 20,
		},
		manualPagination: true,
		manualSorting: true,
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		onRowSelectionChange: setRowSelection,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
			pageSize,
		},

		globalFilterFn: (rows, columnFilters) => {
			const [globalFilter] = columnFilters.filter(
				(filter) => filter.id === "global"
			);
			if (!globalFilter || !globalFilter.value) return rows;

			return rows.filter((row) => {
				const name = row.original.name; // Assuming 'name' is the column key
				return name.includes(globalFilter.value);
			});
		},
		onGlobalFilterChange: (filterValue) => {
			setGlobalFilter(filterValue); // Update the global filter state
		},
	});

	return (
		<>
			{bulkDeleteDocument ? (
				<BulkDeletePopup
					setDocumentDeletedToTrue={setDocumentDeletedToTrue}
					Docbulkid={bulkDeleteDocumentIds}
					bulkDeleteDocument={bulkDeleteDocument}
					setBulkDeleteDocument={setBulkDeleteDocument}
					setRowSelection={setRowSelection}
				/>
			) : null}
			<div className="table-content">
				<div className="w-full bg-white px-2">
					<div className="flex justify-between items-center py-4 flex-wrap gap-4">
						<Input
							placeholder="Search..."
							value={globalFilter}
							onChange={(event) => table.setGlobalFilter(event.target.value)}
							className="max-w-sm"
						/>
						<div className="flex gap-2">
							<Button
								variant="outline"
								className=""
								onClick={() => setShowFilterButton(!showFilterButton)}
							>
								<MixerHorizontalIcon className="mr-1" />
								Filter
							</Button>

							<DropdownMenu>
								<DropdownMenuTrigger asChild>
									<Button variant="outline" className="ml-auto">
										Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
									</Button>
								</DropdownMenuTrigger>
								<DropdownMenuContent align="end">
									{table
										.getAllColumns()
										.filter((column) => column.getCanHide())
										.map((column) => {
											return (
												<DropdownMenuCheckboxItem
													key={column.id}
													className="capitalize"
													checked={column.getIsVisible()}
													onCheckedChange={(value) =>
														column.toggleVisibility(!!value)
													}
												>
													{column.columnDef.displayName}
												</DropdownMenuCheckboxItem>
											);
										})}
								</DropdownMenuContent>
							</DropdownMenu>
						</div>
					</div>
					{showFilterButton && (
						<DataToolbar
							table={table}
							apiUrl="/api/get-document-filter-name"
							// docCategory={docCategory}
							clientID={clientID}
						/>
					)}

					<div className="rounded-md border">
						{loading ? (
							<TableSkeleton />
						) : (
							<Table>
								<TableHeader className="pb-4 sticky top-0 bg-white z-[1]">
									{table.getHeaderGroups().map((headerGroup) => (
										<TableRow key={headerGroup.id}>
											{headerGroup.headers.map((header) => {
												return (
													<TableHead key={header.id}>
														{header.isPlaceholder
															? null
															: flexRender(
																	header.column.columnDef.header,
																	header.getContext()
															  )}
													</TableHead>
												);
											})}
										</TableRow>
									))}
								</TableHeader>
								<TableBody>
									{table.getRowModel().rows?.length ? (
										table.getRowModel().rows.map((row) => (
											<TableRow
												key={row.id}
												data-state={row.getIsSelected() && "selected"}
											>
												{row.getVisibleCells().map((cell) => (
													<TableCell key={cell.id}>
														{flexRender(
															cell.column.columnDef.cell,
															cell.getContext()
														)}
													</TableCell>
												))}
											</TableRow>
										))
									) : (
										<TableRow>
											<TableCell
												colSpan={columns.length}
												className="h-24 text-center"
											>
												{loading ? <TableSkeleton /> : "no records found"}
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						)}
					</div>
					<div className="flex items-center justify-between space-x-2 py-4 flex-wrap gap-4">
						<div className="flex items-center gap-5">
							<div className="flex-1 text-sm text-muted-foreground">
								{table.getFilteredSelectedRowModel().rows.length} of{" "}
								{table.getFilteredRowModel().rows.length} row(s) selected.
							</div>
							<div className="flex items-center space-x-2">
								<p className="text-sm font-medium">Rows per page</p>
								<Select
									value={`${table.getState().pageSize}`}
									onValueChange={(value) => {
										setPageSize(Number(value));
										setCurrentPage(1);
									}}
								>
									<SelectTrigger className="w-auto">
										<SelectValue placeholder={table.getState().pageSize} />
									</SelectTrigger>
									<SelectContent>
										<SelectGroup>
											{[10, 20, 30, 40, 50].map((pageSize) => (
												<SelectItem key={pageSize} value={`${pageSize}`}>
													{pageSize}
												</SelectItem>
											))}
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>
						</div>
						<div className="space-x-2">
							<PaginationSection
								setCurrentPage={setCurrentPage}
								totalPages={totalPages}
								currentPage={currentPage}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DocumentTable;
