import React, { useState, useContext } from "react";
import { MdDelete } from "react-icons/md";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import ButtonUI from "../material-ui/ButtonUI";
import deleteClient from "../../api/deleteClient";
import { AuthContext } from "../../context/Auth/AuthContext";

/*** Component for showing the confirmation message before deleting the client from client table ***/
const DeleteClientPopup = ({ setClientDeletedToTrue, data }) => {
  const { contact_id } = data;
  const [open, setOpen] = useState(false);
  const { token } = useContext(AuthContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDeleteClientFormSubmit = async () => {

    const deleteClientFormResponse = await deleteClient({
      token: token,
      id: contact_id,
    });

    if (deleteClientFormResponse?.code === 200) {
      setClientDeletedToTrue(false);
      setOpen(false);
      toast.success("Client is deleted successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="dialog-modal">
      <ButtonUI
        formButton={false}
        variant="outlined"
        buttonText={<MdDelete />}
        style={{
          backgroundColor: "#dc3545",
          color: "white",
          padding: "8px",
          minWidth: "fit-content",
          border: "none",
        }}
        onClickFunc={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="edit-doc-modal">
          <div className="popup-header-svg-icon">
            <MdDelete />
          </div>
          <div className="popup-header-title">Delete Client</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this client ?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-end" }}>
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="No"
            style={{
              margin: "15px 0px",
              backgroundColor: "#fff",
              color: "#007bff",
              border: "1px solid #007bff",
            }}
            onClickFunc={handleClose}
          />
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="Yes"
            style={{
              margin: "15px",
              backgroundColor: "#fff",
              color: "##007bff",
              border: "1px solid #007bff",
            }}
            autoFocus={true}
            onClickFunc={() => {
              onDeleteClientFormSubmit();
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteClientPopup;
