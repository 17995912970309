import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiFillCloseCircle } from "react-icons/ai";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import addDocumentRequestSettings from "../../api/addDocumentRequestSettings";
import addDocumentRequestSettingsImg from "../../images/add-user.png";
import "../../styles/AddDocument.css";
import getAllSubTypeList from "../../api/getAllSubTypeList";

const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/pdf",
];

const addDocumentRequestSettingsFormSchema = yup.object().shape({
  documentName: yup.string().required("Document Name is required field"),
  description: yup.string().required("Description is required field"),
  requestType: yup.string().required("Request Type is required field"),
  stage: yup.string().required("Stage is required field"),
  ocrStatus: yup.string().required("OCR Status is required field"),
  clientType: yup.string().required("Client Type is required field"),
  clientSubType: yup.string().required("Client Sub Type is required field"),
  /*fileUpload: yup
    .mixed()
    .test("required", "Please select a file", (value) => {
      return value && value.length > 0;
    })
    .test("type", "We only support images and pdf", (value) => {
      return value && SUPPORTED_FORMATS.includes(value[0]?.type);
    })
    .test(
      "fileSize",
      "Please upload file having size less than 5MB",
      (value) => {
        return value && value[0]?.size <= 5000000;
      }
    ),*/
  status: yup.string().required("Status is required field"),

});

/*** Component for adding the Document Request Settings ***/
const AddDocumentRequestSettings = ({ typeOptions, onAddDocumentRequestSettingsButtonClick, setDocumentRequestSettingsAddedToTrue }) => {

  const [loading, setLoading] = useState(false);
  const { token, is_admin, user_id } = useContext(AuthContext);
  const [subTypeOptions, setSubTypeOptions] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addDocumentRequestSettingsFormSchema),
  });

  const selectedClientType = watch("clientType");
  const clientSubTypeLoad = async (clientType) => {
    const typeId = clientType;
    const data = await getAllSubTypeList({ token, typeId });
    if (data.code == 200) {
      const filteredTypeList =
        data?.data !== null
          ? data?.data.map((type) => {
            return {
              label: type.fields_name,
              typeId: type.field_setting_id,
            };
          })
          : [];
      filteredTypeList.unshift({ label: "None", typeId: "None" });
      setSubTypeOptions(filteredTypeList);
    } else {
      setSubTypeOptions([]);
    }
  };


  const requestOptions = [
    {
      name: "Required",
      value: "required",
    },
    {
      name: "Recommended",
      value: "recommended",
    },
  ];

  const stageOptions = [
    {
      name: "Stage 1",
      value: "Stage 1",
    },
    {
      name: "Stage 2",
      value: "Stage 2",
    },
    {
      name: "Stage 3",
      value: "Stage 3",
    },
  ];

  const statusOptions = [
    {
      name: "Active",
      value: "active",
    },
    {
      name: "Inactive",
      value: "inactive",
    },
  ];

  const ocrStatusOptions = [
    {
      name: "True",
      value: "True",
    },
    {
      name: "False",
      value: "False",
    },
  ];

  const onAddDocumentRequestSettingsFormSubmit = async (addDocumentRequestSettingsFormData) => {
    setLoading(true);
    const addDocumentRequestSettingsFormResponse = await addDocumentRequestSettings({
      token: token,
      userId: user_id,
      document_name: addDocumentRequestSettingsFormData.documentName,
      document_desc: addDocumentRequestSettingsFormData.description,
      client_type: addDocumentRequestSettingsFormData.clientType,
      service_sub_type_c: addDocumentRequestSettingsFormData.clientSubType,
      request_type: addDocumentRequestSettingsFormData.requestType,
      document_stage: addDocumentRequestSettingsFormData.stage,
      ocr_status: addDocumentRequestSettingsFormData.ocrStatus,
      status: addDocumentRequestSettingsFormData.status,
      is_admin: is_admin,
      //sample_document_link: addDocumentRequestSettingsFormData.fileUpload[0],
    });

    if (addDocumentRequestSettingsFormResponse?.code === 200) {
      onAddDocumentRequestSettingsButtonClick();
      setDocumentRequestSettingsAddedToTrue();
      toast.success("Document Request Settings is added successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Error while adding Document Request Settings", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    clientSubTypeLoad(selectedClientType);
  }, [selectedClientType]);
  return (
    <div className="add-document-page">
      <div className="add-document-container">
        <div className="add-document-header">
          <div className="add-document-title-with-img">
            <img src={addDocumentRequestSettingsImg} height="100px" width="120px" alt="Add Document Request Template" />
            <div className="add-document-title">Add Document Request Template</div>
          </div>
          <ButtonUI
            formButton={false}
            variant="contained"
            buttonText={<AiFillCloseCircle />}
            style={{
              background: "none",
              padding: "0px",
              minWidth: "auto",
            }}
            onClickFunc={onAddDocumentRequestSettingsButtonClick}
          ></ButtonUI>
        </div>
        <div className="add-document-form ">
          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message">
              <InputUI
                name="documentName"
                label="Enter Document Request Name"
                type="text"
                variant="outlined"
                cssStyles={{
                  margin: "10px 0px",
                }}
                register={register}
              />
              <span className="error-message">
                {errors["documentName"]?.message}
              </span>
            </div>
            <div className="add-document-inputs-with-error-message">
              <InputUI
                name="description"
                label="Enter Description"
                type="text"
                variant="outlined"
                cssStyles={{ margin: "10px 0px" }}
                register={register}
              />
              <span className="error-message">
                {errors["description"]?.message}
              </span>
            </div>
          </div>
          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message select-ui">
              <FormControl fullWidth>
                <InputLabel id="select-label">Request Type</InputLabel>
                <Controller
                  control={control}
                  name="requestType"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="requestType"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="Request Type"
                      {...register("requestType")}
                    >
                      {requestOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <span className="error-message">
                {errors["requestType"]?.message}
              </span>
            </div>
            <div className="add-document-inputs-with-error-message select-ui">
              <FormControl fullWidth>
                <InputLabel id="select-label">Stage</InputLabel>
                <Controller
                  control={control}
                  name="stage"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="stage"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="Stage"
                      {...register("stage")}
                    >
                      {stageOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <span className="error-message">{errors["stage"]?.message}</span>
            </div>
          </div>

          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message select-ui">
              <FormControl fullWidth>
                <InputLabel id="select-label">Client Type</InputLabel>
                <Controller
                  control={control}
                  name="clientType"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="clientType"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="Client Type"
                      {...register("clientType")}
                    >
                      {typeOptions?.map((option) => (
                        <MenuItem key={option.typeId} value={option.typeId}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <span className="error-message">
                {errors["clientType"]?.message}
              </span>
            </div>
            <div className="add-document-inputs-with-error-message select-ui">
              <FormControl fullWidth>
                <InputLabel id="select-label">Client Sub Type</InputLabel>
                <Controller
                  control={control}
                  name="clientSubType"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="clientSubType"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="Client Sub Type"
                      {...register("clientSubType")}
                    >
                      {subTypeOptions?.map((option) => (
                        <MenuItem key={option.typeId} value={option.typeId}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <span className="error-message">
                {errors["clientSubType"]?.message}
              </span>
            </div>
          </div>

          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message select-ui">
              <FormControl fullWidth>
                <InputLabel id="select-label">OCR Status</InputLabel>
                <Controller
                  control={control}
                  name="ocrStatus"
                  defaultValue="False"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="ocrStatus"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="OCR Status"
                      {...register("ocrStatus")}
                    >
                      {ocrStatusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <span className="error-message">
                {errors["ocrStatus"]?.message}
              </span>
            </div>
            <div className="add-document-inputs-with-error-message select-ui">
              <FormControl fullWidth>
                <InputLabel id="select-label">Status</InputLabel>
                <Controller
                  control={control}
                  name="status"
                  defaultValue="active"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="status"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="Status"
                      {...register("status")}
                    >
                      {statusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <span className="error-message">
                {errors["status"]?.message}
              </span>
            </div>
            {/* <div className="add-document-inputs-with-error-message upload-ui">
              <InputUI
                name="fileUpload"
                type="file"
                variant="outlined"
                cssStyles={{
                  margin: "10px 0px",
                }}
                register={register}
              />
              <span className="error-message">
                {errors["fileUpload"]?.message}
              </span>
            </div> */}
          </div>
          <div className="add-document-button">
            <ButtonUI
              loading={loading}
              variant="contained"
              buttonText="Add Document Request Template"
              style={{
                width: "300px",
                height: "35px",
                backgroundColor: "#007bff",
                boxShadow: "none",
                border: "1px solid #007bff",
                fontWeight: "500",
                borderRadius: "4px",
                letterSpacing: "0.07rem",
                marginTop: "20px",
              }}
              handleSubmit={handleSubmit}
              onFormSubmit={onAddDocumentRequestSettingsFormSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDocumentRequestSettings;
