import React, { useState, useEffect, useContext } from "react";
import Loading from "../components/material-ui/Loading";
import Navbar from "../components/common/Navbar";
import { AuthContext } from "../context/Auth/AuthContext";
import dashboardRecentUploads from "../api/dashboardRecentUploads";
import dashboardPendingRequests from "../api/dashboardPendingRequests";
import { BsFillCloudArrowUpFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import "../styles/Dashboard.css";

/*** Main component for showing the dashboard items like 'Pending Requests' and 'Recent Uploads' ***/
const Dashboard = () => {
  const [loading, setLoading] = useState(true);

  const [dashboardRecentUploadsResponse, setDashboardRecentUploadsResponse] = useState({});
  const [dashboardPendingRequestsResponse, setDashboardPendingRequestsResponse] = useState({});

  const { token, user_id, is_admin, firstName, lastName } = useContext(AuthContext);

  const dashboardRecentUploadsData = async () => {
    const response = await dashboardRecentUploads({ token });
    if (response.code === 200) {
      setDashboardRecentUploadsResponse(response.data);
    }
  };

  const dashboardPendingRequestsData = async () => {
    const response = await dashboardPendingRequests({ token });
    if (response.code === 200) {
      setDashboardPendingRequestsResponse(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    dashboardRecentUploadsData();
    dashboardPendingRequestsData();
  }, []);


  const fulldate = () => {
    let dd = new Date();
    let month = dd.toLocaleString("en-US", { month: "long" });
    let dt = dd.toLocaleString("en-US", { day: "2-digit" });
    let yr = dd.getFullYear();
    let fdate = `${month} ${dt}, ${yr}`;
    return fdate;
  };

  const greet = () => {
    let dd = new Date();
    let hrs = dd.getHours();

    if (hrs === 0 || hrs < 12) {
      return "Good Morning";
    } else if (hrs >= 21) {
      return "Good Night";
    } else if (hrs >= 16) {
      return "Good Evening";
    } else {
      return "Good Afternoon";
    }
  };

  const greetMsg = () => {
    let dd = new Date();

    let day = dd.getDay();

    if (day === 0 || day === 6) {
      return " HAPPY WEEKEND !!! :) ";
    } else {
      return "Welcome to NBL DMS";
    }
  };

  return (
    <div className="dashboard-container">
      <div className="navbar">
        <Navbar />
      </div>
      {!loading ? (
        <div className="dashboard-page-content">
          <div className="dashboard-column-3">
            <div id="welcome" className="grid-dashlet">
              <div className="d-flex justify-content-between align-content-center">
                <p className="greetings">
                  <span className="greet-text"> {greet()} {" "}{`${firstName} ${lastName}`}</span> {" "}
                  !
                </p>
                <p className="fullDate"> {fulldate()}</p>
              </div>
              <p>{greetMsg()}</p>
            </div>
          </div>


          <div className="dahboard-column">
            <div className="notification-section-one">
              <div className="flex items-center notification-title-requests">
                <span
                  className="request-doc-request-icon request-doc-icon"
                  style={{ position: "relative", marginRight: "10px" }}
                >
                  <BsFillCloudArrowUpFill />
                </span>
                Pending Requests</div>
              <div className="notification-container">
                {dashboardPendingRequestsResponse.length > 0 ? (
                  dashboardPendingRequestsResponse.map((pendingRequests) => (
                    <div className="latest-notification" key={pendingRequests.contact_id} >
                      <Link to={`/clients/${pendingRequests.contact_id}`} className="go-to-password-reset-form" target="_blank">
                        {pendingRequests.name}
                      </Link>
                    </div>
                  ))
                ) : (
                  <div className="latest-notification">No pending requests</div>
                )}
              </div>
            </div>
            <div className="notification-section-two">
              <div className="flex items-center notification-title-recent">
                <span
                  className="request-doc-upload-icon request-doc-icon"
                  style={{ position: "relative", marginRight: "10px" }}
                ><BsFillCloudArrowUpFill />
                </span>
                Recent Uploads</div>
              <div className="notification-container">
                {dashboardRecentUploadsResponse.length > 0 ? (
                  dashboardRecentUploadsResponse.map((recentUploads) => {
                    const date = new Date(recentUploads.created_at);
                    const day = String(date.getDate()).padStart(2, '0');
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const year = date.getFullYear();
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const seconds = String(date.getSeconds()).padStart(2, '0');

                    const formattedDate = `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;

                    return (

                      <div className="latest-notification" key={recentUploads.contact_id} >
                        <Link to={`/clients/${recentUploads.contact_id}/uploads`} className="go-to-password-reset-form" target="_blank">
                          {recentUploads.name} at {formattedDate}
                        </Link>
                      </div>
                    )
                  })
                ) : (<div className="latest-notification">No recent uploads</div>)}
              </div>
            </div>
          </div>

          {/* <div className="dashboard-column-2">
            <div className="documentlist">
              <h5><HiOutlineClipboardList /> Pending Requests</h5>
              <div>
                <ul>
                  <li>
                    <Link to="" className="documentlist-items">
                      &nbsp;Doc 1
                    </Link>
                  </li>
                  <li>
                    <Link to="" className="documentlist-items">
                      &nbsp;Doc 2
                    </Link>
                  </li>
                  <li>
                    <Link to="" className="documentlist-items">
                      &nbsp;Doc 3
                    </Link>
                  </li>
                  <li>
                    <Link to="" className="documentlist-items">
                      &nbsp;Doc 4
                    </Link>
                  </li>
                  <li>
                    <Link to="" className="documentlist-items">
                      &nbsp;Doc 5
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="documentlist">
              <h5><AiOutlineFieldTime /> Recent Uploads</h5>
              <div>
                <ul>
                  <li>
                    <Link to="" className="documentlist-items">
                      &nbsp;Upload 1
                    </Link>
                  </li>
                  <li>
                    <Link to="" className="documentlist-items">
                      &nbsp;Upload 2
                    </Link>
                  </li>
                  <li>
                    <Link to="" className="documentlist-items">
                      &nbsp;Upload 3
                    </Link>
                  </li>
                  <li>
                    <Link to="" className="documentlist-items">
                      &nbsp;Upload 4
                    </Link>
                  </li>
                  <li>
                    <Link to="" className="documentlist-items">
                      &nbsp;Upload 5
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}

        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Dashboard;
