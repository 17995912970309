import baseUrl from "../config/baseUrl";

const addClientFieldSettings = async ({
    token,
    userId,
    fields_name,
    fields_tab_type,
    fields_description,
    status,
    is_admin,
    category_id
}) => {
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("fields_name", fields_name);
    formData.append("fields_tab_type", fields_tab_type);
    formData.append("fields_description", fields_description);
    formData.append("status", status);
    formData.append("is_admin", is_admin);
    if (category_id && category_id !== 'None') {
        formData.append("category_id", category_id);
    }
    try {
        const response = await fetch(`${baseUrl}/api/add-fields-setting-data`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

export default addClientFieldSettings;
