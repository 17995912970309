import React, { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";

const EditDocumentForm = ({
	register,
	control,
	errors,
	tableValues,
	//categoryOptions,
	subCategoryOptions,
}) => {
	// const statusOptions = [
	//   {
	//     name: "Active",
	//     value: "active",
	//   },
	//   {
	//     name: "Inactive",
	//     value: "inactive",
	//   },
	// ];

	return (
		<Grid className="edit-document">
			<div className="edit-document-form grid grid-cols-1 gap-3 sm:grid-cols-2">
				{tableValues.document_request_setting_name &&
				tableValues.document_request_setting_name != "Additional Document" ? (
					<div className="field_wrapper">
						<div className="field">
							<Controller
								name="document_requested"
								defaultValue={tableValues.document_request_setting_name}
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputUI
										name="document_requested"
										label="Linked Request"
										type="text"
										disabled
										value={value ? value : ""}
										setValue={onChange}
										variant="outlined"
										cssStyles={{
											margin: "10px 0px",
										}}
										register={register}
									/>
								)}
							/>
						</div>
					</div>
				) : null}
				<div className="field_wrapper">
					<div className="field">
						<Controller
							name="document_name"
							defaultValue={tableValues.document_name}
							control={control}
							render={({ field: { onChange, value } }) => (
								<InputUI
									name="document_name"
									label="Document Name"
									type="text"
									value={value ? value : ""}
									setValue={onChange}
									variant="outlined"
									cssStyles={{
										margin: "10px 0px",
									}}
									register={register}
								/>
							)}
						/>
						<span className="error-message">
							{errors["document_name"]?.message}
						</span>
					</div>
				</div>
				<div className="field_wrapper">
					<div className="field">
						<Controller
							name="document_desc"
							defaultValue={tableValues.document_desc || ""}
							control={control}
							render={({ field: { onChange, value } }) => (
								<InputUI
									name="document_desc"
									label="Document Description"
									type="text"
									value={value && value !== null ? value : ""}
									setValue={onChange}
									variant="outlined"
									cssStyles={{
										margin: "10px 0px",
									}}
									register={register}
								/>
							)}
						/>
						{/* <span className="error-message">{errors["document_desc"]?.message}</span> */}
					</div>
				</div>
				<div className="field_wrapper">
					<div className="field">
						<Controller
							name="category"
							defaultValue={tableValues.doc_category}
							control={control}
							render={({ field: { onChange, value } }) => (
								<InputUI
									name="category"
									label="Category"
									type="text"
									value={value && value !== null ? value : ""}
									setValue={onChange}
									variant="outlined"
									disabled
									cssStyles={{
										margin: "10px 0px",
									}}
									register={register}
								/>
							)}
						/>
						{/* <FormControl fullWidth>
            <InputLabel id="select-label">Category</InputLabel>
            <Controller
              control={control}
              name="category"
              defaultValue={tableValues?.category_id}
              render={({ field: { onChange, value } }) => (
                <Select
                  name="category"
                  sx={{ margin: "10px 0px" }}
                  labelId="select-label"
                  variant="outlined"
                  id="simple-select"
                  value={value}
                  onChange={onChange}
                  label="Category"
                  {...register("category")}
                >
                  {categoryOptions?.map((category) => (
                    <MenuItem
                      key={category.fields_name}
                      value={category.field_setting_id}
                    >
                      {category.fields_name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
            <span className="error-message">{errors["category"]?.message}</span> */}
					</div>
				</div>
				<div className="field_wrapper">
					<FormControl fullWidth>
						<InputLabel id="select-label">Sub Category</InputLabel>
						<Controller
							control={control}
							name="subcategory"
							defaultValue={
								tableValues?.document_sub_category &&
								tableValues?.document_sub_category !== "null"
									? tableValues?.document_sub_category
									: ""
							}
							render={({ field: { onChange, value } }) => (
								<Select
									name="subcategory"
									sx={{ margin: "10px 0px" }}
									labelId="select-label"
									variant="outlined"
									id="simple-select"
									value={value ? value : ""}
									onChange={onChange}
									label="Sub Category"
									{...register("subcategory")}
								>
									{subCategoryOptions?.map((category) => (
										<MenuItem
											key={category.field_setting_id}
											value={category.field_setting_id}
										>
											{category.fields_name}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</FormControl>
					<span className="error-message">
						{errors["subcategory"]?.message}
					</span>
				</div>
				{/* <div className="field_wrapper" style={{ marginTop: "10px" }}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Status</InputLabel>
            <Controller
              control={control}
              name="document_status"
              defaultValue={tableValues.document_status.toLowerCase().replace(/\s+/g, '')}
              render={({ field: { onChange, value } }) => (
                <Select
                  name="document_status"
                  sx={{ margin: "10px 0px" }}
                  labelId="select-label"
                  variant="outlined"
                  id="simple-select"
                  value={value}
                  onChange={onChange}
                  label="Status"
                  {...register("document_status")}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <span className="add-doc-error-message">
            {errors["addClientStatus"]?.message}
          </span>
        </div> */}
			</div>
		</Grid>
	);
};

export default EditDocumentForm;
