import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { TiDeleteOutline } from "react-icons/ti";
import { HiDownload } from "react-icons/hi";
import { FaEye } from "react-icons/fa";
import getAllDocumentList from "../../../api/getAllDocumentList";
import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import linkDocument from "../../../api/linkDocument";
import "../../../styles/LinkPopup.css";
import downloadLink from "../../../api/downloadLink";

const LinkPopup = ({
  tableValues,
  clientID,
  setCategoryUpdatedToTrue,
}) => {

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allDocList, setAllDocList] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const { token } = useContext(AuthContext);

  const onLinkPopupLoad = async () => {
    setLoading(true);
    const data = await getAllDocumentList({ token, clientID });

    const filteredDocList =
      data?.data !== null
        ? data?.data.map((doc) => {
          return {
            label: doc.document_name,
            DocumentID: doc.document_id,
          };
        })
        : [];
    setAllDocList(filteredDocList);
    setLoading(false);
  };

  const handleClickOpen = () => {
    onLinkPopupLoad();
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedDocument(null);
    setOpen(false);
  };

  const openLinkInNewTab = async () => {
    const docRequestId = tableValues.document_request_id;

    const downloadLinkFormResponse = await downloadLink({
      token: token,
      docRequestId: docRequestId,
    });

    if (downloadLinkFormResponse?.code === 200 && downloadLinkFormResponse?.data[0]?.pdflink) {
      const link = downloadLinkFormResponse?.data[0].pdflink;
      //window.open(`${link}`);
      downloadFile(`${link}`);
    } else {
      toast.error("Download URL does not Exists", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  function downloadFile(documentUrl) {

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = documentUrl;

    // Set the download attribute to prompt the browser to download the file instead of navigating to it
    link.setAttribute('download', 'download.pdf');
    link.setAttribute('target', '_blank');

    // Hide the link
    link.style.display = 'none';

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the click event of the link
    link.click();

    // Clean up by removing the link from the body
    document.body.removeChild(link);
  }

  const onLinkDocumentButton = async () => {

    const onLinkDocumentButtonResponse = await linkDocument({
      token: token,
      docRequestId: tableValues.document_request_id,
    });

    if (onLinkDocumentButtonResponse.code === 200) {
      setOpen(false);
      setCategoryUpdatedToTrue();
      toast.success("Document linked successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Document not linked. Please check.", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    tableValues.document_status?.toLowerCase() === "uploaded" || tableValues.document_status?.toLowerCase() === "approved" ? (
      <div className="single-link-popup-container">
        <ButtonUI
          formButton={false}
          variant="outlined"
          buttonText={tableValues.document_status?.toLowerCase() === "uploaded" ? <FaEye /> : <HiDownload />}
          style={{
            backgroundColor: tableValues.document_status?.toLowerCase() === "uploaded" ? "#ffc107" : "#17a2b8",
            color: " white",
            marginRight: "5px",
            padding: "8px",
            minWidth: "fit-content",
            border: "none",
          }}
          onClickFunc={
            openLinkInNewTab
          }
        />


        {/* <ButtonUI
        formButton={false}
        variant="outlined"
        buttonText={
          tableValues.document_status?.toLowerCase() === "approved" ? <HiDownload /> : <BiLinkAlt />
        }
        style={{
          backgroundColor: "#17a2b8",
          color: " white",
          marginRight: "5px",
          padding: "8px",
          minWidth: "fit-content",
          border: "none",
        }}
        onClickFunc={
          tableValues.document_status?.toLowerCase() === "approved"
            ? openLinkInNewTab
            : handleClickOpen
        }
      /> */}
        {tableValues.document_status?.toLowerCase() !== "approved" ? (
          open ? (
            <div className="single-doc-link-popup-content">
              {!loading ? (
                allDocList.length !== 0 ? (
                  <>
                    <Autocomplete
                      className="select-doc-select-ui"
                      id="select-file-option"
                      options={allDocList}
                      sx={{ width: 350 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Document" />
                      )}
                      value={selectedDocument}
                      onChange={(e, selectedOption) =>
                        setSelectedDocument(selectedOption)
                      }
                    />
                    <div className="link-popup-action-btn">
                      <ButtonUI
                        formButton={false}
                        variant="contained"
                        buttonText="Cancel"
                        autoFocus={true}
                        style={{
                          backgroundColor: "rgb(215 18 18)",
                          color: "white",
                          letterSpacing: "0.06rem",
                          padding: "3px 15px",
                          marginRight: "5px",
                          marginTop: "10px",
                          marginBottom: "5px",
                        }}
                        onClickFunc={handleClose}
                      />
                      <ButtonUI
                        formButton={false}
                        variant="contained"
                        buttonText="Submit"
                        autoFocus={true}
                        style={{
                          backgroundColor:
                            selectedDocument !== null ? "#007bff" : "white",
                          color: selectedDocument !== null ? "white" : "#007bff",
                          border:
                            selectedDocument !== null
                              ? "none"
                              : "1px solid #007bff",
                          letterSpacing: "0.06rem",
                          padding: "3px 15px",
                          marginRight: "5px",
                          marginTop: "10px",
                          marginBottom: "5px",
                        }}
                        disabled={selectedDocument !== null ? false : true}
                        onClickFunc={onLinkDocumentButton}
                      />
                    </div>
                  </>
                ) : (
                  <div className="no-doc-link-popup-data">
                    <div>No Documents found</div>
                    <ButtonUI
                      formButton={false}
                      variant="contained"
                      buttonText={<TiDeleteOutline />}
                      autoFocus={true}
                      style={{
                        backgroundColor: "transparent",
                        color: "rgb(215 18 18)",
                        marginRight: "5px",
                        padding: "8px",
                        minWidth: "fit-content",
                        border: "none",
                        marginLeft: "10px",
                      }}
                      onClickFunc={handleClose}
                    />
                  </div>
                )
              ) : (
                <div className="loading-link-popup-data">Loading Data</div>
              )}
            </div>
          ) : null
        ) : null}
      </div>
    ) : (
      <></>
    )
  );
};

export default LinkPopup;
