import baseUrl from "../config/baseUrl";

const getAllSubTypeList = async ({ token, typeId }) => {
    try {
        const response = await fetch(
            `${baseUrl}/api/get-sub-type?type=${typeId}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

export default getAllSubTypeList;
