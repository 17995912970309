import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";

const EditDocumentForm = ({ register, control, errors, tableValues }) => {
  const roleOptions = [
    {
      name: "Admin",
      value: 1,
    },
    {
      name: "Non Admin",
      value: 0,
    },
  ];

  const statusOptions = [
    {
      name: "Active",
      value: "active",
    },
    {
      name: "Inactive",
      value: "inactive",
    },
  ];

  return (
    <Grid className="">
      <div className="edit-document-form grid grid-cols-1 gap-3 sm:grid-cols-2">
        <div className="field_wrapper">
          <div className="field">
            <div className="input-field">
              <Controller
                name="firstName"
                defaultValue={tableValues.first_name}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputUI
                    name="firstName"
                    label="First Name"
                    type="text"
                    value={value ? value : ""}
                    setValue={onChange}
                    variant="outlined"
                    register={register}
                  />
                )}
              />
            </div>
            <span className="error-message">
              {errors["firstName"]?.message}
            </span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <div className="input-field">
              <Controller
                name="lastName"
                defaultValue={tableValues.last_name}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputUI
                    name="lastName"
                    label="Last Name"
                    type="text"
                    value={value ? value : ""}
                    setValue={onChange}
                    variant="outlined"
                    register={register}
                  />
                )}
              />
            </div>
            <span className="error-message">{errors["lastName"]?.message}</span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field" style={{ height: "55px" }}>
            <FormControl className="" fullWidth>
              <InputLabel className="edit-doc-select-label" id="select-label">
                Role
              </InputLabel>
              <Controller
                control={control}
                name="role"
                defaultValue={tableValues.is_admin}
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="role"
                    labelId="select-label"
                    variant="outlined"
                    id="simple-select"
                    value={value}
                    onChange={onChange}
                    label="Role"
                    {...register("role")}
                  >
                    {roleOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <span className="error-message">{errors["role"]?.message}</span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <FormControl className="" fullWidth>
              <InputLabel className="edit-doc-select-label" id="select-label">
                Status
              </InputLabel>
              <Controller
                control={control}
                name="status"
                defaultValue={tableValues.status.toLowerCase()}
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="status"
                    labelId="select-label"
                    variant="outlined"
                    id="simple-select"
                    value={value}
                    onChange={onChange}
                    label="Status"
                    {...register("status")}
                  >
                    {statusOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <span className="error-message">{errors["status"]?.message}</span>
          </div>
        </div>

      </div>
    </Grid>
  );
};

export default EditDocumentForm;
