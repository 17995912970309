const { useCallback, useEffect } = require("react");
const { useState } = require("react");
const { useDebounce } = require("use-debounce");

const useDebounceSearch = (InitialValue, delay, callbackFn) => {
    const [searchTerm, setSearchTerm] = useState(InitialValue);
    const [debouncedSearchTerm] = useDebounce(searchTerm, delay);

    const handleClientNameSearch = useCallback((newSearchTerm) => {
        setSearchTerm(newSearchTerm);
    }, []);

    useEffect(() => {
        //if (debouncedSearchTerm) {
        callbackFn(debouncedSearchTerm);
        //}
    }, [debouncedSearchTerm, callbackFn]);

    return [searchTerm, handleClientNameSearch];
};

export default useDebounceSearch;
