import React, { useState, useEffect, useContext } from "react";
import {
	CaretSortIcon,
	ChevronDownIcon,
	EyeOpenIcon,
	MixerHorizontalIcon,
} from "@radix-ui/react-icons";
import {
	ColumnDef,
	ColumnFiltersState,
	SortingState,
	VisibilityState,
	getFacetedRowModel,
	getFacetedUniqueValues,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";

import { Button } from "../../shadcn-components/ui/button";
import { Checkbox } from "../../shadcn-components/ui/checkbox";
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "../../shadcn-components/ui/dropdown-menu";
import { Input } from "../../shadcn-components/ui/input";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../../shadcn-components/ui/table";

import clientsData from "../../api/clients";
import { AuthContext } from "../../context/Auth/AuthContext";
import baseUrl from "../../config/baseUrl";
import AddClient from "./AddClient";
import EditClientPopup from "./EditClientPopup";
import "../../styles/Clients.css";
import "../../styles/DocumentTable.css";
import DeleteClientPopup from "./DeleteClientPopup";
import getAllTypeList from "../../api/getAllFieldTypeList";
import BulkDeletePopup from "./BulkDeletePopup";
import TableSkeleton from "../common/TableSkeleton";

import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../../shadcn-components/ui/select";

import PaginationSection from "../common/PaginationSection";
import DataToolbar from "../common/DataToolbar";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "../../shadcn-components/ui/tooltip";
import LinkContactPopup from "./client-documents/LinkContactPopup";
import { UserPlus } from "lucide-react";
import getAllSubTypeList from "../../api/getAllSubTypeList";
import { useDebounce } from "use-debounce";

/*** Component for listing all the client details in the table ***/
const Clients = ({
	addClientTab,
	showAddClientForm,
	onAddClientButtonClick,
	clientId,
	showClientIdSearch,
}) => {
	const [loading, setLoading] = useState(false);

	let idSearch = "";
	if (clientId !== undefined) {
		idSearch = `&contact_id=${clientId}`;
	}
	if (showClientIdSearch) {
		idSearch = "";
	}
	const [data, setData] = useState([]);

	const { token, user_id, is_admin } = useContext(AuthContext);
	const [clientAdded, setClientAdded] = useState(0);
	const [clientDeleted, setClientDeleted] = useState(0);
	const [bulkDeleteClient, setBulkDeleteClient] = useState(false);
	const [bulkDeleteClientIds, setBulkDeleteClientIds] = useState();

	const [typeOptions, setTypeOptions] = useState([]);

	const [sorting, setSorting] = useState([]);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnVisibility, setColumnVisibility] = useState({});
	const [rowSelection, setRowSelection] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalPages, setTotalPages] = useState(1);
	const [globalFilter, setGlobalFilter] = useState("");
	const [debouncedGlobalFilter] = useDebounce(globalFilter, 1000);
	const [showFilterButton, setShowFilterButton] = useState(false);
	const [isBeneficiaryAdded, setIsBeneficiaryAdded] = useState(false);
	const [subTypeOptions, setSubTypeOptions] = useState([]);

	const type = "client_type";

	const clientSubTypeLoad = async (selectedClientType) => {
		const typeId = selectedClientType;
		const data = await getAllSubTypeList({ token, typeId });
		if (data.code == 200) {
			const filteredTypeList =
				data?.data !== null
					? data?.data.map((type) => {
						return {
							label: type.fields_name,
							typeId: type.field_setting_id,
						};
					})
					: [];
			filteredTypeList.unshift({ label: "None", typeId: "None" });
			console.log(data?.data);
			setSubTypeOptions(filteredTypeList);
		} else {
			setSubTypeOptions([]);
		}
	};

	const columns = [
		{
			id: "select",
			header: ({ table }) => (
				<div className="flex items-center">
					<Checkbox
						checked={
							table.getIsAllPageRowsSelected() ||
							(table.getIsSomePageRowsSelected() && "indeterminate")
						}
						onCheckedChange={(value) =>
							table.toggleAllPageRowsSelected(!!value)
						}
						aria-label="Select all"
					/>
					{(table.getIsSomePageRowsSelected() ||
						table.getIsAllPageRowsSelected()) && (
							<DropdownMenu>
								<DropdownMenuTrigger asChild>
									<Button
										variant="outline"
										className="p-0 !border-none !shadow-none bg-transparent"
									>
										<ChevronDownIcon className="" />
									</Button>
								</DropdownMenuTrigger>
								<DropdownMenuContent align="end">
									<DropdownMenuItem onSelect={() => handleBulkAction("delete")}>
										Delete
									</DropdownMenuItem>
								</DropdownMenuContent>
							</DropdownMenu>
						)}
				</div>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => row.toggleSelected(!!value)}
					aria-label="Select row"
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: "name",
			displayName: "Client Name",
			filterable: true,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Client Name
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div
					className="capitalize cursor-pointer text-blue-500 underline"
					onClick={() => {
						addClientTab({ ...row.original, typeOptions });
					}}
				>
					{row.getValue("name")}
				</div>
			),
			enableHiding: false,
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},

		{
			accessorKey: "phone_number",
			displayName: "Phone Number",
			filterable: true,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Phone Number
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="text-left">{row.getValue("phone_number")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "email_id",
			displayName: " Email Id",
			filterable: true,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Email Id
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="text-left">{row.getValue("email_id")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "fields_name",
			displayName: "Client Category",
			filterable: true,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Client Category
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => {
				const fields_name = row.getValue("fields_name");

				return <div className="capitalize">{fields_name}</div>;
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "client_sub_type",
			displayName: "Client Sub Category",
			filterable: true,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Client Sub Category
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => {
				const client_sub_type = row.getValue("client_sub_type");

				return <div className="capitalize">{client_sub_type}</div>;
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "client_reference_number",
			displayName: "Reference Number",
			filterable: true,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Reference Number
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="text-left">
					{row.getValue("client_reference_number")}
				</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "beneficiary_number",
			displayName: "Beneficiary",
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Beneficiary
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div
					className="text-left underline text-blue-600 cursor-pointer"
					onClick={() => {
						addClientTab({ ...row.original, beneficiary: true });
					}}
				>
					{row.getValue("beneficiary_number")}
				</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "status",
			displayName: "Last Status",
			filterable: true,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Last Status
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">
					{row.getValue("status").toLowerCase() === "active"
						? "Active"
						: "Inactive"}
				</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "updated_at",
			displayName: "Modified At",
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Modified At
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("updated_at")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			id: "actions",
			displayName: "Actions",
			enableHiding: false,
			header: ({ column }) => (
				<Button variant="ghost" className="p-0 !cursor-default">
					Actions
				</Button>
			),
			cell: ({ row }) => {
				return (
					<div className="flex gap-2">
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger>
									<EditClientPopup
										setClientUpdatedToTrue={setClientAddedToTrue}
										tableValues={row.original}
										typeOptions={typeOptions}
									/>
								</TooltipTrigger>
								<TooltipContent className="bg-gray-600">
									<p>Edit Client</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger>
									<DeleteClientPopup
										setClientDeletedToTrue={setClientDeletedToTrue}
										data={row.original}
									/>
								</TooltipTrigger>
								<TooltipContent className="bg-gray-600">
									<p>Delete Client</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger>
									<LinkContactPopup
										setIsBeneficiaryAdded={setIsBeneficiaryAdded}
										data={row.original}
										typeOptions={typeOptions}
									/>
								</TooltipTrigger>
								<TooltipContent className="bg-gray-600">
									<p>Add Beneficiary</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger>
									<button
										className="bg-[#6463631a] text-[#403f3f] p-2 rounded-sm"
										onClick={() => {
											addClientTab({
												...row.original,
												beneficiary: true,
												typeOptions,
											});
										}}
									>
										<EyeOpenIcon className="text-lg" />
									</button>
								</TooltipTrigger>
								<TooltipContent className="bg-gray-600">
									<p>View Beneficiary</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					</div>
				);
			},
		},
	];

	const handleBulkAction = async (actionType) => {
		const selectedRowsData = table
			.getFilteredSelectedRowModel()
			.rows.map((row) => row.original);
		const ids = selectedRowsData.map((row) => row.contact_id).join(",");
		if (actionType === "delete") {
			setBulkDeleteClientIds(ids);
			setBulkDeleteClient(true);
		}
	};

	const clientTypeLoad = async () => {
		const data = await getAllTypeList({ token, type });
		if (data.code == 200) {
			const filteredTypeList =
				data?.data !== null
					? data?.data.map((type) => {
						return {
							label: type.fields_name,
							typeId: type.field_setting_id,
						};
					})
					: [];
			setTypeOptions(filteredTypeList);
		} else {
			setTypeOptions([]);
		}
	};

	const clientResponseFunc = async () => {
		setLoading(true);
		const offset = currentPage - 1;
		const filterParams = {};

		columnFilters.forEach((filterItem) => {
			filterParams[filterItem.id] = filterItem.value;
		});

		const sortParam = sorting
			.map((sortItem) => {
				return `${sortItem.id}=${sortItem.desc ? "desc" : "asc"}`;
			})
			.join(",");

		const params = {
			isAdmin: is_admin,
			userId: user_id,
			limit: pageSize,
			offset: offset,
			...filterParams,
			global_search: debouncedGlobalFilter,
			sort: sortParam,
		};
		const queryString = new URLSearchParams(params).toString();
		const url = `${baseUrl}/api/get-all-client-data?${queryString}${idSearch}`;

		const response = await clientsData({ token, api: url });
		if (response.code == 200) {
			setTotalPages(Math.ceil(response?.data[0]?.allclientsCount / pageSize));
			setData(response?.data[0]?.clients);

			const matchingClient = response?.data[0]?.clients?.filter(
				(client) => client.contact_id === clientId
			);

			if (matchingClient.length > 0) {
				addClientTab(matchingClient[0]);
			}
		} else {
			setTotalPages(1);
			setData([]);
		}
		setLoading(false);
	};

	const setClientAddedToTrue = () => {
		setClientAdded((count) => count + 1);
	};

	const setClientDeletedToTrue = () => {
		setClientDeleted((count) => count + 1);
	};

	useEffect(() => {
		clientTypeLoad();
	}, []);

	useEffect(() => {
		clientResponseFunc();
	}, [
		clientAdded,
		clientDeleted,
		bulkDeleteClient,
		isBeneficiaryAdded,
		currentPage,
		columnFilters,
		debouncedGlobalFilter,
		pageSize,
		sorting,
	]);

	const table = useReactTable({
		data: data,
		columns,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		initialState: {
			pageSize: 20,
		},
		manualPagination: true,
		manualSorting: true,
		getSortedRowModel: getSortedRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		onRowSelectionChange: setRowSelection,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
			pageSize,
		},

		globalFilterFn: (rows, columnFilters) => {
			const [globalFilter] = columnFilters.filter(
				(filter) => filter.id === "global"
			);
			if (!globalFilter || !globalFilter.value) return rows;

			return rows.filter((row) => {
				const name = row.original.name.toLowerCase(); // Assuming 'name' is the column key
				return name.includes(globalFilter.value.toLowerCase());
			});
		},
		onGlobalFilterChange: (filterValue) => {
			setGlobalFilter(filterValue); // Update the global filter state
		},
	});

	return (
		<>
			{bulkDeleteClient ? (
				<BulkDeletePopup
					setClientDeletedToTrue={setClientDeletedToTrue}
					Clientbulkid={bulkDeleteClientIds}
					bulkDeleteClient={bulkDeleteClient}
					setBulkDeleteClient={setBulkDeleteClient}
					setRowSelection={setRowSelection}
				/>
			) : null}
			<div className="document-table">
				{showAddClientForm ? (
					<AddClient
						typeOptions={typeOptions}
						onAddClientButtonClick={onAddClientButtonClick}
						setClientAddedToTrue={setClientAddedToTrue}
						subTypeOptions={subTypeOptions}
						clientSubTypeLoad={clientSubTypeLoad}
					/>
				) : (
					<div className="documents-content">
						<div className="document__table mt-3">
							<div className="w-full bg-white px-2">
								<div className="flex justify-between items-center py-4 flex-wrap gap-4">
									<Input
										placeholder="Search Client Name..."
										value={globalFilter}
										onChange={(event) =>
											table.setGlobalFilter(event.target.value)
										}
										className="max-w-sm"
									/>
									<div className="flex gap-2">
										<Button
											variant="outline"
											className=""
											onClick={() => setShowFilterButton(!showFilterButton)}
										>
											<MixerHorizontalIcon className="mr-1" />
											Filter
										</Button>
										<DropdownMenu>
											<DropdownMenuTrigger asChild>
												<Button variant="outline" className="ml-auto">
													Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
												</Button>
											</DropdownMenuTrigger>
											<DropdownMenuContent align="end">
												{table
													.getAllColumns()
													.filter((column) => column.getCanHide())
													.map((column) => {
														return (
															<DropdownMenuCheckboxItem
																key={column.id}
																className="capitalize"
																checked={column.getIsVisible()}
																onCheckedChange={(value) =>
																	column.toggleVisibility(!!value)
																}
															>
																{column.columnDef.displayName}
															</DropdownMenuCheckboxItem>
														);
													})}
											</DropdownMenuContent>
										</DropdownMenu>
									</div>
								</div>
								{showFilterButton && (
									<DataToolbar
										table={table}
										apiUrl="/api/get-client-filter-name"
									/>
								)}
								<div className="rounded-md border">
									{loading ? (
										<TableSkeleton />
									) : (
										<Table>
											<TableHeader className="pb-4 sticky top-0 bg-white z-[1]">
												{table.getHeaderGroups().map((headerGroup) => (
													<TableRow key={headerGroup.id}>
														{headerGroup.headers.map((header) => {
															return (
																<TableHead key={header.id}>
																	{header.isPlaceholder
																		? null
																		: flexRender(
																			header.column.columnDef.header,
																			header.getContext()
																		)}
																</TableHead>
															);
														})}
													</TableRow>
												))}
											</TableHeader>
											<TableBody>
												{table.getRowModel().rows?.length ? (
													table.getRowModel().rows.map((row) => (
														<TableRow
															key={row.id}
															data-state={row.getIsSelected() && "selected"}
														>
															{row.getVisibleCells().map((cell) => (
																<TableCell key={cell.id}>
																	{flexRender(
																		cell.column.columnDef.cell,
																		cell.getContext()
																	)}
																</TableCell>
															))}
														</TableRow>
													))
												) : (
													<TableRow>
														<TableCell
															colSpan={columns.length}
															className="h-24 text-center"
														>
															{loading ? <TableSkeleton /> : "no records found"}
														</TableCell>
													</TableRow>
												)}
											</TableBody>
										</Table>
									)}
								</div>
								<div className="flex items-center justify-between space-x-2 py-4 flex-wrap gap-4">
									<div className="flex items-center gap-5">
										<div className="flex-1 text-sm text-muted-foreground">
											{table.getFilteredSelectedRowModel().rows.length} of{" "}
											{table.getFilteredRowModel().rows.length} row(s) selected.
										</div>
										<div className="flex items-center space-x-2">
											<p className="text-sm font-medium">Rows per page</p>
											<Select
												value={`${table.getState().pageSize}`}
												onValueChange={(value) => {
													setPageSize(Number(value));
													setCurrentPage(1);
												}}
											>
												<SelectTrigger className="w-auto">
													<SelectValue
														placeholder={table.getState().pageSize}
													/>
												</SelectTrigger>
												<SelectContent>
													<SelectGroup>
														{[10, 20, 30, 40, 50].map((pageSize) => (
															<SelectItem key={pageSize} value={`${pageSize}`}>
																{pageSize}
															</SelectItem>
														))}
													</SelectGroup>
												</SelectContent>
											</Select>
										</div>
									</div>
									<div className="space-x-2">
										<PaginationSection
											setCurrentPage={setCurrentPage}
											totalPages={totalPages}
											currentPage={currentPage}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Clients;
