import baseUrl from "../config/baseUrl";

const requestedDocumentPopup = async ({
  token,
  stage,
  clientType,
  clientID
}) => {
  try {
    const response = await fetch(
      `${baseUrl}/api/get-document-name-by-client-type?document_stage=${stage}&contact_id=${clientID}`,
      // `${baseUrl}/api/get-document-name-by-client-type?client_type=${clientType}&document_stage=${stage}&contact_id=${clientID}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default requestedDocumentPopup;
