import React, { useState, useEffect } from "react";
import ImageCanvas from "./ImageCanvas";
import axios from "axios";
import { LoaderCircle } from "lucide-react";
import { toast } from "react-toastify";

const OcrContainer = ({ documentLink, setOcrData }) => {
	const [imageSrc, setImageSrc] = useState("");
	const [file, setFile] = useState(null);
	const [shapes, setShapes] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		// Set the image URL directly
		setImageSrc(documentLink);
	}, []);

	const handleSelectArea = (newShape) => {
		setShapes([...shapes, newShape]);
	};

	const handlePerformOcr = async () => {
		const roundedShapes = shapes.map((shape) => ({
			start: {
				x: Math.round(shape.start.x),
				y: Math.round(shape.start.y),
			},
			end: {
				x: Math.round(shape.end.x),
				y: Math.round(shape.end.y),
			},
		}));

		try {
			setLoading(true);
			const formData = new FormData();
			formData.append("imageLink", imageSrc);
			formData.append("areas", JSON.stringify(roundedShapes));
			const response = await axios.post(
				"https://filescour.consumerlaw.com/ocr",
				formData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer 29649f8f-4055-4b7e-b5db-c3e57d875d66",
					},
				}
			);

			const data = response.data.output;

			console.log(data);

			setOcrData(data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error(
				"Error performing OCR:",
				error.response ? error.response.data : error.message
			);
			toast.error("Error while performing OCR !", {
				position: "top-center",
				autoClose: 5000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	return (
		<div>
			{imageSrc && (
				<ImageCanvas imageSrc={imageSrc} onSelectArea={handleSelectArea} />
			)}
			{shapes.length > 0 && (
				<button
					onClick={handlePerformOcr}
					className="bg-[#1976D2] text-white p-2 rounded-sm w-[180px] text-center flex items-center justify-center disabled:cursor-not-allowed disabled:opacity-80 mt-9"
					disabled={loading}
				>
					{loading ? <LoaderCircle className="animate-spin" /> : "Perform OCR"}
				</button>
			)}
		</div>
	);
};

export default OcrContainer;
