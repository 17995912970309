import React, { useState, useContext, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { RiCloseCircleFill } from "react-icons/ri";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import requestedDocumentPopup from "../../../api/requestDocumentPopup";
import "../../../styles/RequestDocumentsPopup.css";
import saveRequestedDocument from "../../../api/saveRequestedDocument";
import getBeneficiary from "../../../api/getBeneficiary";
import baseUrl from "../../../config/baseUrl";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <RiCloseCircleFill />
        </IconButton>
      )}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

const RequestDocumentsPopup = ({
  clientID,
  contactID,
  status,
  documentTypeArray,
  setCategoryUpdatedToTrue,
  clientType,
  setSelectedClientId,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [tags, setTags] = useState([]);
  const [selectedDocumentRequestedStage, setSelectedDocumentRequestedStage] =
    useState("All");
  const [contactList, setContactList] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);

  const { token, user_id } = useContext(AuthContext);

  const fetchContacts = async () => {
    const url = `${baseUrl}/api/get-client-link-data?contact_id=${clientID}`;
    try {
      const response = await getBeneficiary({ api: url, token });
      const contacts = [...response.clients, ...response.client_link_contacts];
      setContactList(contacts);
      if (contacts.length > 0) {
        setSelectedContact(contacts[0]);
        onRequestDocumentInPopupFunc(contacts[0]);
      }
    } catch (err) {
      console.error("Error fetching contact list", err);
    }
  };

  const onRequestDocumentInPopupFunc = async (selectedContactInfo) => {
    setLoading(true);
    //setSelectedClientId(selectedContactInfo?.contact_id);

    try {
      const onRequestDocumentInPopupResponse = await requestedDocumentPopup({
        token: token,
        stage: selectedDocumentRequestedStage,
        clientType: selectedContactInfo?.type || clientType,
        clientID: selectedContactInfo?.contact_id || clientID,
      });

      if (onRequestDocumentInPopupResponse.code === 200) {
        const requiredTagsData =
          onRequestDocumentInPopupResponse?.data?.length === 0
            ? []
            : onRequestDocumentInPopupResponse?.data?.map((doc) => ({
              contact_id: selectedContactInfo?.contact_id,
              document_name: doc.document_name,
              document_category: "Required",
              user_id: user_id,
              document_request_setting_id: doc.document_request_setting_id,
            }));
        // setSelectedClientId(selectedContactInfo?.contact_id);
        setTags(requiredTagsData);
      } else {
        setTags([]);
      }
    } catch (err) {
      console.error("Error requesting document in popup", err);
      setTags([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      setSelectedDocumentRequestedStage("All");
      fetchContacts();
    }
  }, [open]);

  useEffect(() => {
    if (selectedContact) {
      onRequestDocumentInPopupFunc(selectedContact);
    }
  }, [selectedDocumentRequestedStage, selectedContact]);

  const requestedDocumentStageArray = ["All", "Stage 1", "Stage 2", "Stage 3"];

  const handleKeyDown = (e) => {
    let contactId = clientID;
    if (selectedContact?.contact_id) {
      contactId = selectedContact?.contact_id;
    }
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setTags([
      ...tags,
      {
        contact_id: contactId,
        document_name: value,
        document_category: "Recommended",
        user_id: user_id,
      },
    ]);
    e.target.value = "";
  };

  const removeTag = (index) => {
    const filteredTags = tags.filter((tag, i) => i !== index);
    setTags(filteredTags);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onRequestDocumentFormSubmit = async () => {
    setSubmitLoading(true);
    try {
      const requestDocument = await saveRequestedDocument({
        token: token,
        tags: tags,
      });

      if (requestDocument.code === 200) {
        setOpen(false);
        setCategoryUpdatedToTrue();
        setSubmitLoading(false);
        toast.success("Requested Documents are saved successfully", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      console.error("Error saving requested documents", err);
      setSubmitLoading(false);
      toast.error("Failed to save requested documents", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="dialog-modal">
      <ButtonUI
        formButton={false}
        variant="contained"
        buttonText="Request Documents"
        style={{
          width: "177px",
          height: "35px",
          color: "#fff",
          marginRight: "10px",
          backgroundColor: "#f6bf01",
          boxShadow: "none",
          fontWeight: "200",
          borderRadius: "4px",
          letterSpacing: "0.07rem",
        }}
        onClickFunc={handleClickOpen}
      ></ButtonUI>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          className="edit-doc-modal"
          onClose={handleClose}
        >
          <div className="popup-header-svg-icon">
            <AiOutlineCloudUpload />
          </div>
          <div className="popup-header-title">Request Document</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div className="request-doc-top-section gap-6 flex flex-col lg:flex-row">
            <FormControl className="request-doc-select-ui">
              <InputLabel
                className="request-doc-select-label"
                id="select-label"
              >
                Stage
              </InputLabel>
              <Select
                name="addDocCategories"
                sx={{ margin: "10px 0px", maxWidth: "20" }}
                labelId="select-label"
                variant="outlined"
                id="simple-select"
                value={selectedDocumentRequestedStage}
                onChange={(e) =>
                  setSelectedDocumentRequestedStage(e.target.value)
                }
                label="Category"
              >
                {requestedDocumentStageArray.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="request-doc-select-ui">
              <InputLabel
                className="request-doc-select-label"
                id="contact-label"
              >
                Contact
              </InputLabel>
              <Select
                name="contacts"
                sx={{ margin: "10px 0px", maxWidth: "20" }}
                labelId="contact-label"
                variant="outlined"
                id="contact-select"
                value={selectedContact?.contact_id || ""}
                onChange={(e) => {
                  const selectedContactInfo = contactList.find(
                    (contact) => contact.contact_id === e.target.value
                  );
                  setSelectedContact(selectedContactInfo);
                  // onRequestDocumentInPopupFunc(selectedContactInfo);
                }}
                label="Contact"
              >
                {contactList.map((contact) => (
                  <MenuItem key={contact.contact_id} value={contact.contact_id}>
                    {contact.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="tags-input-container">
            <div className="tags-input-content">
              {!loading ? (
                <>
                  {tags.map((tag, index) => (
                    <div className="tag-item" key={index}>
                      <span className="text">{tag.document_name}</span>
                      <span className="close" onClick={() => removeTag(index)}>
                        <RiCloseCircleFill />
                      </span>
                    </div>
                  ))}
                  <input
                    onKeyDown={handleKeyDown}
                    placeholder="Type here ..."
                    type="text"
                    className="tags-input"
                  />
                </>
              ) : (
                "Loading Document List ..."
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="popup_submit_btn">
            <ButtonUI
              loading={submitLoading}
              formButton={false}
              variant="contained"
              buttonText="Submit"
              disabled={tags.length === 0}
              autoFocus={true}
              onClickFunc={onRequestDocumentFormSubmit}
            />
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default RequestDocumentsPopup;
