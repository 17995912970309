import React, { useContext } from "react";
import { FaEye } from "react-icons/fa";
import ButtonUI from "../../../material-ui/ButtonUI";
import { AuthContext } from "../../../../context/Auth/AuthContext";
import viewDocumentLink from "../../../../api/viewDocumentLink";
import { toast } from "react-toastify";
import "../../../../styles/LinkPopup.css";

/*** Component for viewing the document in pdf viewer ***/
const DocumentViewer = ({ tableValues, changeDocumentViewerState }) => {
	const { token } = useContext(AuthContext);

	const openLinkInNewTab = async () => {
		const downloadLinkFormResponse = await viewDocumentLink({
			token: token,
			docId: tableValues.document_id,
		});

		if (
			downloadLinkFormResponse?.code === 200 &&
			downloadLinkFormResponse?.data[0]?.pdflink
		) {
			const link = downloadLinkFormResponse?.data[0].pdflink;
			//window.open(`${link}`);
			changeDocumentViewerState(
				tableValues.document_id,
				tableValues.document_request_id,
				downloadLinkFormResponse?.data[0]?.document_status,
				`${link}`,
				tableValues.document_name,
				tableValues.fields_name,
				tableValues.document_desc,
				tableValues.document_sub_category,
				tableValues.document_type
			);
		} else {
			toast.error("Download URL does not Exists", {
				position: "top-center",
				autoClose: 5000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	return (
		<div className="single-link-popup-container">
			<ButtonUI
				formButton={false}
				variant="outlined"
				buttonText={<FaEye />}
				style={{
					backgroundColor: "#ffc107",
					color: "white",
					marginRight: "5px",
					padding: "8px",
					minWidth: "fit-content",
					border: "none",
				}}
				onClickFunc={openLinkInNewTab}
			/>
		</div>
	);
};

export default DocumentViewer;
