import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import {
	MdOutlineArrowBackIos,
	MdOutlineArrowForwardIos,
} from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import TextField from "@mui/material/TextField";
import Navbar from "../components/common/Navbar";
import fieldSettingsData from "../api/fieldSettings";
import FieldSettingsTable from "../components/fields-setting/FieldSettingsTable";
import { AuthContext } from "../context/Auth/AuthContext";
import baseUrl from "../config/baseUrl";
import AddFieldSettings from "../components/fields-setting/AddFieldSettings";
import "../styles/Users.css";

import StatusPopup from "../components/fields-setting/StatusPopup";
import EditFieldSettingsPopup from "../components/fields-setting/EditFieldSettingsPopup";
import DeleteFieldSettingsPopup from "../components/fields-setting/DeleteFieldSettingsPopup";
import BulkDeletePopup from "../components/fields-setting/BulkDeletePopup";

import { Button } from "../shadcn-components/ui/button";
import { Checkbox } from "../shadcn-components/ui/checkbox";
import getAllTypeList from "../api/getAllFieldTypeList";

import {
	CaretSortIcon,
	ChevronDownIcon,
	StarIcon,
	StarFilledIcon,
} from "@radix-ui/react-icons";
import {
	ColumnDef,
	ColumnFiltersState,
	SortingState,
	VisibilityState,
	flexRender,
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";

import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "../shadcn-components/ui/dropdown-menu";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "../shadcn-components/ui/tooltip";
import { useDebounce } from "use-debounce";

/*** Main component for listing all the Field Settings details & for showing 'Add FieldSettings' button ***/
const FieldSettings = () => {
	const [loading, setLoading] = useState(true);
	const [pageCount, setPageCount] = useState(0);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 20,
	});
	const [showAddFieldSettingsForm, setShowAddFieldSettingsForm] =
		useState(false);

	const [fieldSettingsAdded, setFieldSettingsAdded] = useState(0);

	const { token, id, is_admin, user_id } = useContext(AuthContext);
	const [fieldSettingsResponse, setFieldSettingsResponse] = useState([]);
	const [bulkDeleteFieldSetting, setBulkDeleteFieldSetting] = useState(false);
	const [bulkDeleteFieldSettingIds, setBulkDeleteFieldSettingIds] = useState();

	const [sorting, setSorting] = useState([]);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnVisibility, setColumnVisibility] = useState({});
	const [rowSelection, setRowSelection] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalPages, setTotalPages] = useState(1);
	const [globalFilter, setGlobalFilter] = useState("");
	const [debouncedGlobalFilter] = useDebounce(globalFilter, 1000);
	const [selectAllChecked, setSelectAllChecked] = useState(false);
	const [showFilterButton, setShowFilterButton] = useState(false);
	const [clientTypeOptions, setClientTypeOptions] = useState([]);

	const FieldSettingsResponseFunc = async () => {
		setLoading(true);
		const offset = currentPage - 1;
		const filterParams = {};

		columnFilters.forEach((filterItem) => {
			filterParams[filterItem.id] = filterItem.value;
		});

		const sortParam = sorting
			.map((sortItem) => {
				return `${sortItem.id}=${sortItem.desc ? "desc" : "asc"}`;
			})
			.join(",");

		const params = {
			isAdmin: is_admin,
			userId: user_id,
			limit: pageSize,
			offset: offset,
			...filterParams,
			global_search: globalFilter,
			sort: sortParam,
		};
		const queryString = new URLSearchParams(params).toString();

		const url = `${baseUrl}/api/get-all-fields-setting-data?${queryString}`;
		const response = await fieldSettingsData({ token, api: url });
		if (response.code == 200) {
			setTotalPages(Math.ceil(response?.data[0].allCasesCount / pageSize));
			setFieldSettingsResponse(response?.data[0].cases);
		} else {
			setTotalPages(0);
			setFieldSettingsResponse([]);
		}
		setLoading(false);
	};

	useEffect(() => {
		FieldSettingsResponseFunc();
	}, [
		fieldSettingsAdded,
		bulkDeleteFieldSetting,
		debouncedGlobalFilter,
		currentPage,
		columnFilters,
		sorting,
		pageSize,
	]);

	const type = "client_type";
	const clientTypeLoad = async () => {
		const data = await getAllTypeList({ token, type });
		if (data.code === 200) {
			const filteredTypeList = data?.data
				? data.data.map((type) => ({
						label: type.fields_name,
						typeId: type.field_setting_id,
				  }))
				: [];
			filteredTypeList.unshift({ label: "None", typeId: "None" });
			setClientTypeOptions(filteredTypeList);
			setClientTypeOptions(filteredTypeList);
		} else {
			setClientTypeOptions([]);
		}
	};

	useEffect(() => {
		clientTypeLoad();
	}, [fieldSettingsAdded]);

	const onAddFieldSettingsButtonClick = () => {
		setShowAddFieldSettingsForm(!showAddFieldSettingsForm);
	};

	const setFieldSettingsAddedToTrue = () => {
		setFieldSettingsAdded((count) => count + 1);
	};

	const handlePageClick = ({ selected: selectedPage }) => {
		setCurrentPage(selectedPage);
	};

	const handleBulkAction = async (actionType) => {
		const selectedRowsData = table
			.getFilteredSelectedRowModel()
			.rows.map((row) => row.original);
		const ids = selectedRowsData.map((row) => row.field_setting_id).join(",");
		if (actionType === "delete") {
			setBulkDeleteFieldSettingIds(ids);
			setBulkDeleteFieldSetting(true);
		}
	};

	const columns = [
		{
			id: "select",
			header: ({ table }) => (
				<div className="flex items-center">
					<Checkbox
						checked={
							table.getIsAllPageRowsSelected() ||
							(table.getIsSomePageRowsSelected() && "indeterminate")
						}
						onCheckedChange={(value) =>
							table.toggleAllPageRowsSelected(!!value)
						}
						aria-label="Select all"
					/>
					{(table.getIsSomePageRowsSelected() ||
						table.getIsAllPageRowsSelected()) && (
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button
									variant="outline"
									className="p-0 !border-none !shadow-none bg-transparent"
								>
									<ChevronDownIcon className="" />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="end">
								<DropdownMenuItem onSelect={() => handleBulkAction("delete")}>
									Delete
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					)}
				</div>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => {
						setSelectAllChecked(true);
						row.toggleSelected(!!value);
					}}
					aria-label="Select row"
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},

		{
			accessorKey: "fields_name",
			displayName: "Fields Name",
			filterable: true,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Fields Name
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("fields_name")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "fields_tab_type",
			filterable: true,
			displayName: "Field Type",
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Field Type
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">
					{row.getValue("fields_tab_type").toLowerCase() === "document_type"
						? "Document Type"
						: row.getValue("fields_tab_type").toLowerCase() === "client_subtype"
						? "Client Sub Type"
						: row.getValue("fields_tab_type").toLowerCase() === "client_type"
						? "Client Type"
						: ""}
				</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "category_name",
			filterable: true,
			displayName: "Category",
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Category
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("category_name")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "fields_description",
			filterable: true,
			displayName: "Description",
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Description
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("fields_description")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "status",
			displayName: "Status",
			filterable: true,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Status
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">
					{row.getValue("status").toLowerCase() === "active"
						? "active"
						: "Inactive"}
				</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "created_at",
			displayName: "Created At",
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Created At
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("created_at")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "updated_at",
			displayName: "Modified At",
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Modified At
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("updated_at")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			id: "actions",
			displayName: "Actions",
			header: ({ column }) => (
				<Button variant="ghost" className="p-0 !cursor-default">
					Actions
				</Button>
			),
			cell: ({ row }) => (
				<div className="flex gap-px">
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger>
								<span>
									<StatusPopup
										setFieldSettingsAddedToTrue={setFieldSettingsAddedToTrue}
										tableValues={row.original}
									/>
								</span>
							</TooltipTrigger>
							<TooltipContent className="bg-gray-600">
								<p>Change Status</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>

					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger>
								<span>
									<EditFieldSettingsPopup
										setFieldSettingsAddedToTrue={setFieldSettingsAddedToTrue}
										tableValues={row.original}
										clientTypeOptions={clientTypeOptions}
									/>
								</span>
							</TooltipTrigger>
							<TooltipContent className="bg-gray-600">
								<p>Edit</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>

					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger>
								<span>
									<DeleteFieldSettingsPopup
										setFieldSettingsAddedToTrue={setFieldSettingsAddedToTrue}
										id={row.original.field_setting_id}
									/>
								</span>
							</TooltipTrigger>
							<TooltipContent className="bg-gray-600">
								<p>Delete</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
			),
			enableHiding: false,
		},
	];

	const table = useReactTable({
		data: fieldSettingsResponse,
		columns,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		initialState: {
			pageSize: 20,
		},
		manualPagination: true,
		manualSorting: true,
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		onRowSelectionChange: setRowSelection,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
			pageSize,
		},

		onGlobalFilterChange: (filterValue) => {
			setGlobalFilter(filterValue);
		},
	});

	console.log(fieldSettingsResponse);
	console.log(table.getRowModel());

	return (
		<>
			{bulkDeleteFieldSetting ? (
				<BulkDeletePopup
					FieldSettingbulkid={bulkDeleteFieldSettingIds}
					bulkDeleteFieldSetting={bulkDeleteFieldSetting}
					setBulkDeleteFieldSetting={setBulkDeleteFieldSetting}
					setRowSelection={setRowSelection}
				/>
			) : null}
			<div className="user-container">
				<div className="navbar">
					<Navbar />
				</div>
				<div className="user-table">
					{showAddFieldSettingsForm ? (
						<AddFieldSettings
							onAddFieldSettingsButtonClick={onAddFieldSettingsButtonClick}
							setFieldSettingsAddedToTrue={setFieldSettingsAddedToTrue}
							clientTypeOptions={clientTypeOptions}
						/>
					) : (
						<div className="user-content">
							{is_admin === "1" ? (
								<button
									className="add-document"
									onClick={onAddFieldSettingsButtonClick}
								>
									Add Field Setting
								</button>
							) : null}

							<div className="user-table-content">
								<FieldSettingsTable
									loading={loading}
									fieldSettingsTableData={fieldSettingsResponse}
									setFieldSettingsAddedToTrue={setFieldSettingsAddedToTrue}
									table={table}
									globalFilter={globalFilter}
									setGlobalFilter={setGlobalFilter}
									columns={columns}
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
									totalPages={totalPages}
									showFilterButton={showFilterButton}
									setShowFilterButton={setShowFilterButton}
									setPageSize={setPageSize}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default FieldSettings;
