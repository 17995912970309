import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import {
	MdOutlineArrowBackIos,
	MdOutlineArrowForwardIos,
} from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import Navbar from "../components/common/Navbar";

import userData from "../api/user";
import UserTable from "../components/user-table/UserTable";
import { AuthContext } from "../context/Auth/AuthContext";
import baseUrl from "../config/baseUrl";
import AddUser from "../components/user-table/AddUser";
import "../styles/Users.css";
import { Button } from "../shadcn-components/ui/button";
import { Checkbox } from "../shadcn-components/ui/checkbox";
import StatusPopup from "../components/user-table/StatusPopup";
import EditUserPopup from "../components/user-table/EditUserPopup";
import DeleteUserPopup from "../components/user-table/DeleteUserPopup";
import BulkDeletePopup from "../components/user-table/BulkDeletePopup";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "../shadcn-components/ui/tooltip";

import {
	CaretSortIcon,
	ChevronDownIcon,
	StarIcon,
	StarFilledIcon,
} from "@radix-ui/react-icons";
import {
	ColumnDef,
	ColumnFiltersState,
	SortingState,
	VisibilityState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";

import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "../shadcn-components/ui/dropdown-menu";

/*** Main component for listing all the Users & for showing 'Add User' button ***/
const Users = () => {
	const [loading, setLoading] = useState(true);
	const [pageCount, setPageCount] = useState(0);

	const [showAddUserForm, setShowAddUserForm] = useState(false);
	const [userAdded, setUserAdded] = useState(0);
	const [userResponse, setUserResponse] = useState([]);

	const [bulkDeleteUser, setBulkDeleteUser] = useState(false);
	const [bulkDeleteUserIds, setBulkDeleteUserIds] = useState();

	const { token, is_admin, user_id } = useContext(AuthContext);
	const [sorting, setSorting] = useState([]);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnVisibility, setColumnVisibility] = useState({});
	const [rowSelection, setRowSelection] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [totalPages, setTotalPages] = useState(1);
	const [globalFilter, setGlobalFilter] = useState("");
	const [selectAllChecked, setSelectAllChecked] = useState();
	const [showFilterButton, setShowFilterButton] = useState(false);

	const UserResponseFunc = async () => {
		setLoading(true);
		const offset = currentPage - 1;
		const filterParams = {};

		columnFilters.forEach((filterItem) => {
			filterParams[filterItem.id] = filterItem.value;
		});

		const sortParam = sorting
			.map((sortItem) => {
				return `${sortItem.id}=${sortItem.desc ? "desc" : "asc"}`;
			})
			.join(",");

		const params = {
			isAdmin: is_admin,
			userId: user_id,
			limit: pageSize,
			offset: offset,
			...filterParams,
			global_search: globalFilter,
			sort: sortParam,
		};
		const queryString = new URLSearchParams(params).toString();

		const url = `${baseUrl}/api/get_user_data?${queryString}`;
		const response = await userData({ token, api: url });
		setTotalPages(Math.ceil(response?.user_count / pageSize));
		setUserResponse(response?.data);
		setLoading(false);
	};

	useEffect(() => {
		UserResponseFunc();
	}, [
		userAdded,
		bulkDeleteUser,
		globalFilter,
		currentPage,
		columnFilters,
		pageSize,
		sorting,
	]);

	const onAddUserButtonClick = () => {
		setShowAddUserForm(!showAddUserForm);
	};

	const setUserAddedToTrue = () => {
		setUserAdded((count) => count + 1);
	};

	const handleBulkAction = async (actionType) => {
		const selectedRowsData = table
			.getFilteredSelectedRowModel()
			.rows.map((row) => row.original);
		const ids = selectedRowsData.map((row) => row.id).join(",");
		if (actionType === "delete") {
			setBulkDeleteUserIds(ids);
			setBulkDeleteUser(true);
		}
	};

	const columns = [
		{
			id: "select",
			header: ({ table }) => (
				<div className="flex items-center">
					<Checkbox
						checked={
							table.getIsAllPageRowsSelected() ||
							(table.getIsSomePageRowsSelected() && "indeterminate")
						}
						onCheckedChange={(value) =>
							table.toggleAllPageRowsSelected(!!value)
						}
						aria-label="Select all"
					/>
					{(table.getIsSomePageRowsSelected() ||
						table.getIsAllPageRowsSelected()) && (
							<DropdownMenu>
								<DropdownMenuTrigger asChild>
									<Button
										variant="outline"
										className="p-0 !border-none !shadow-none bg-transparent"
									>
										<ChevronDownIcon className="" />
									</Button>
								</DropdownMenuTrigger>
								<DropdownMenuContent align="end">
									<DropdownMenuItem onSelect={() => handleBulkAction("delete")}>
										Delete
									</DropdownMenuItem>
								</DropdownMenuContent>
							</DropdownMenu>
						)}
				</div>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => {
						setSelectAllChecked(true);
						row.toggleSelected(!!value);
					}}
					aria-label="Select row"
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},

		{
			accessorKey: "first_name",
			displayName: "First Name",
			filterable: true,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					First Name
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("first_name")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "last_name",
			displayName: "Last Name",
			filterable: true,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Last Name
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("last_name")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "user_name",
			displayName: "User Name",
			filterable: true,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					User Name
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("user_name")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "email",
			displayName: "Email",
			filterable: true,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Email
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="">{row.getValue("email")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "is_admin",
			filterable: true,
			displayName: "Role",
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Role
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">
					{row.getValue("is_admin") === "1" ? "Admin" : "Non Admin"}
				</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "status",
			displayName: "Status",
			filterable: true,
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Status
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">
					{row.getValue("status").toLowerCase() === "active"
						? "Active"
						: "Inactive"}
				</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "updated_at",
			displayName: "Modified At",
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Modified At
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("updated_at")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			id: "actions",
			displayName: "Actions",
			enableHiding: false,
			header: ({ column }) => (
				<Button variant="ghost" className="p-0 !cursor-default">
					Actions
				</Button>
			),
			cell: ({ row }) => (
				<div className="flex items-center gap-px">
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger>
								<span>
									<StatusPopup
										setUserAddedToTrue={setUserAddedToTrue}
										tableValues={row.original}
									/>
								</span>
							</TooltipTrigger>
							<TooltipContent className="bg-gray-600">
								<p>Change Status</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>

					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger>
								<span>
									<EditUserPopup
										setUserAddedToTrue={setUserAddedToTrue}
										tableValues={row.original}
									/>
								</span>
							</TooltipTrigger>
							<TooltipContent className="bg-gray-600">
								<p>Edit</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>

					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger>
								<span>
									<DeleteUserPopup
										setUserAddedToTrue={setUserAddedToTrue}
										id={row.original.id}
									/>
								</span>
							</TooltipTrigger>
							<TooltipContent className="bg-gray-600">
								<p>Delete</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
			),
		},
	];

	const table = useReactTable({
		data: userResponse,
		columns,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		initialState: {
			pageSize: 20,
		},
		manualPagination: true,
		manualSorting: true,
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		onColumnVisibilityChange: setColumnVisibility,
		onRowSelectionChange: setRowSelection,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
			pageSize,
		},

		globalFilterFn: (rows, columnFilters) => {
			const [globalFilter] = columnFilters.filter(
				(filter) => filter.id === "global"
			);
			if (!globalFilter || !globalFilter.value) return rows;

			return rows.filter((row) => {
				const name = row.original.name.toLowerCase(); // Assuming 'name' is the column key
				return name.includes(globalFilter.value.toLowerCase());
			});
		},
		onGlobalFilterChange: (filterValue) => {
			setGlobalFilter(filterValue); // Update the global filter state
		},
	});

	return (
		<>
			{bulkDeleteUser ? (
				<BulkDeletePopup
					Userbulkid={bulkDeleteUserIds}
					bulkDeleteUser={bulkDeleteUser}
					setBulkDeleteUser={setBulkDeleteUser}
					setRowSelection={setRowSelection}
				/>
			) : null}
			<div className="user-container">
				<div className="navbar">
					<Navbar />
				</div>
				<div className="user-table">
					{showAddUserForm ? (
						<AddUser
							onAddUserButtonClick={onAddUserButtonClick}
							setUserAddedToTrue={setUserAddedToTrue}
						/>
					) : (
						<div className="user-content">
							{is_admin === "1" ? (
								<button className="add-document" onClick={onAddUserButtonClick}>
									Add User
								</button>
							) : null}

							<div className="user-table-content">
								<UserTable
									pageCount={pageCount}
									loading={loading}
									userTableData={userResponse}
									setUserAddedToTrue={setUserAddedToTrue}
									table={table}
									globalFilter={globalFilter}
									setGlobalFilter={setGlobalFilter}
									columns={columns}
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
									totalPages={totalPages}
									showFilterButton={showFilterButton}
									setShowFilterButton={setShowFilterButton}
									setPageSize={setPageSize}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Users;
